
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Workspaces from "@/store/modules/Workspaces";
import { Workspace, WorkspaceStyles, Colour } from "@/graphql/API";
import DialogCardTitle from "@/components/ui/DialogCardTitle.vue";
import ColourPaletteList from "@/components/values/ColourPaletteList.vue";
import ColourPicker from "@/components/values/ColourPicker.vue";
import { updateUiColours } from "@/helpers/UiColourHelper";
import WorkspaceLogoUpload from "@/components/workspaces/WorkspaceLogoUpload.vue";
import HTMLEditor from "@/components/ui/texteditor/HTMLEditor.vue";

const workspacesModule = getModule(Workspaces);

@Component({
    components: {
        DialogCardTitle,
        ColourPaletteList,
        ColourPicker,
        WorkspaceLogoUpload,
        HTMLEditor,
    },
})
export default class WorkspaceEditor extends Vue {
    @Prop({ default: false, type: Boolean })
    edit!: boolean;

    @Prop()
    workspace!: Workspace;

    private loading = false;

    private workspaceName = "";
    private description = "";
    private workspaceOptions: WorkspaceStyles = {};

    private showLogoWarning = false;

    get title(): string {
        if (this.edit && this.workspace) {
            return `Edit ${this.workspace.name}`;
        } else {
            return "New Workspace";
        }
    }

    get currentdWorkspace(): number | null {
        return workspacesModule.selectedWorkspaceId;
    }

    get workspaceId(): number | null {
        return this.workspace ? this.workspace.id : null;
    }

    get workspaceOptionsJson(): string {
        return JSON.stringify(this.workspaceOptions);
    }

    get primaryColour(): string {
        if (this.workspaceOptions?.ui_colours?.primary) {
            return this.workspaceOptions.ui_colours.primary;
        } else {
            return "#673ab7";
        }
    }

    /* Start of Colour control Functions */

    private clearColour(index: string) {
        if (this.workspaceOptions.ui_colours) {
            Vue.delete(this.workspaceOptions.ui_colours, index);
        }
    }

    private setColour(index: string, colour: string) {
        if (this.workspaceOptions && this.workspaceOptions.ui_colours) {
            Vue.set(this.workspaceOptions.ui_colours, index, colour);
        } else {
            Vue.set(this.workspaceOptions, "ui_colours", { [index]: colour });
        }
    }

    private changeIsComplementary(val: boolean) {
        Vue.set(this.workspaceOptions, "is_complementary", val);
    }

    private changeColourList(
        list: { [id: string]: Colour },
        saturation_list: { [id: string]: Colour }
    ) {
        Vue.set(this.workspaceOptions, "palette_list", list);
        Vue.set(this.workspaceOptions, "saturation_palette", saturation_list);
    }

    /* End of Colour control Functions */

    private async createWorkspace() {
        this.loading = true;
        if (this.edit && this.workspace) {
            await workspacesModule.updateWorkspace({
                workspace_id: this.workspace.id,
                name: this.workspaceName,
                json: this.workspaceOptionsJson,
                description: this.description,
            });
            if (this.workspace.id == this.currentdWorkspace) {
                if (this.workspaceOptions.ui_colours) {
                    await updateUiColours(this.workspaceOptions.ui_colours);
                }
            }
        } else {
            await workspacesModule.createWorkspace({
                name: this.workspaceName,
                json: this.workspaceOptionsJson,
                description: this.description,
            });
        }
        this.loading = false;
    }

    private async saveLogo(data: {
        fileName: string;
        srcFile: string;
    }): Promise<void> {
        if (data) {
            Vue.set(this.workspaceOptions, "logo", data.fileName);
            if (this.edit) {
                this.createWorkspace();
            }
        }
    }

    private async deleteLogo(): Promise<void> {
        Vue.delete(this.workspaceOptions, "logo");
        if (this.edit) {
            this.createWorkspace();
        }
    }

    mounted(): void {
        this.onWorkspaceChange();
    }

    private updateTextDescription(val: string) {
        this.description = val;
    }

    @Watch("workspaceId")
    onWorkspaceChange(): void {
        if (this.edit && this.workspace) {
            this.workspaceName = this.workspace.name;
            this.workspaceOptions = this.workspace.json
                ? JSON.parse(this.workspace.json)
                : {};
            this.description = this.workspace.description
                ? this.workspace.description
                : "";
        } else {
            this.workspaceName = "";
            this.workspaceOptions = {};
            this.description = "";
        }
    }
}
