
import { Vue, Component, Prop } from "vue-property-decorator";
import Logo from "@/components/ui/Logo.vue";
import { User } from "@/graphql/API";
import { Urls } from "@/Urls";
import { Auth } from "aws-amplify";
import { getModule } from "vuex-module-decorators";
import UsersModule from "@/store/modules/Users";

@Component({
    components: {},
})
export default class InviteForm extends Vue {
    @Prop()
    user!: User;

    @Prop()
    workspaceId!: number;

    @Prop()
    code!: string;

    private readonly URLS = Urls;
    private loading = false;

    private error = false;

    private async logout(): Promise<void> {
        this.$emit("loading-start");
        this.loading = true;
        await Auth.signOut();
        this.loading = false;
        this.$emit("loading-end");
    }

    private async openWorkspace(): Promise<void> {
        if (this.workspaceId) {
            if (this.code) {
                try {
                    await getModule(UsersModule).redeemPermission(this.code);
                    const link = `${window.location.origin}?w=${this.workspaceId}`;
                    window.open(link, "_self");
                } catch (e) {
                    this.error = true;
                    console.log("Code not good")
                    console.log(e);
                }
            } else {
                const link = `${window.location.origin}?w=${this.workspaceId}`;
                window.open(link, "_self");
            }
        }
    }
}
