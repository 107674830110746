var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"vp-html-editor",class:{ 'vp-html-editor--active': _vm.editorActive },attrs:{"tabindex":"0"}},[(_vm.editor)?[(_vm.editorActive)?_c('div',{staticClass:"vp-html-editor__tools"},[_c('ToolBar',{attrs:{"undo-active":_vm.editor.can().undo(),"redo-active":_vm.editor.can().redo(),"bold-active":_vm.editor.isActive('bold'),"italic-active":_vm.editor.isActive('italic'),"underline-active":_vm.editor.isActive('underline'),"strike-active":_vm.editor.isActive('strike'),"link-active":_vm.editor.isActive('link'),"number-list-active":_vm.editor.isActive('orderedList'),"table-active":_vm.pasteTable,"header-one-active":_vm.editor.isActive('heading', { level: 1 }),"header-two-active":_vm.editor.isActive('heading', { level: 2 }),"header-three-active":_vm.editor.isActive('heading', { level: 3 })},on:{"undo":function($event){_vm.editor.chain().focus().undo().run()},"redo":function($event){_vm.editor.chain().focus().redo().run()},"bold":function($event){_vm.editor.chain().focus().toggleBold().run()},"italic":function($event){_vm.editor.chain().focus().toggleItalic().run()},"underline":function($event){_vm.editor.chain().focus().toggleUnderline().run()},"strike":function($event){_vm.editor.chain().focus().toggleStrike().run()},"link":_vm.setLink,"link-off":function($event){_vm.editor.chain().focus().unsetLink().run()},"number-list":function($event){_vm.editor.chain().focus().toggleOrderedList().run()},"bullet-list":function($event){_vm.editor.chain().focus().toggleBulletList().run()},"table":function($event){_vm.pasteTable
                        ? (_vm.pasteTable = false)
                        : ((_vm.pasteTable = true), (_vm.pasteImage = false))},"table-option":_vm.tableOption,"header-one":function($event){_vm.editor.chain().focus().toggleHeading({ level: 1 }).run()},"header-two":function($event){_vm.editor.chain().focus().toggleHeading({ level: 2 }).run()},"header-three":function($event){_vm.editor.chain().focus().toggleHeading({ level: 3 }).run()}}})],1):_vm._e(),_c('div',[_c('v-input',{staticClass:"vp__richtext-input-wrapper",class:{
                    'vp__richtext-input-wrapper--active':
                        _vm.errMessages.length > 0,
                },attrs:{"rules":[
                    _vm.rules.isStringMinValid(
                        _vm.editor.getHTML(),
                        _vm.minLength,
                        _vm.maxLength
                    ),
                    _vm.rules.isStringMaxValid(
                        _vm.editor.getHTML(),
                        _vm.minLength,
                        _vm.maxLength
                    ),
                ],"error-messages":_vm.errMessages}},[_c('editor-content',{style:(_vm.style),attrs:{"editor":_vm.editor}})],1)],1),_c('v-progress-linear',{style:(`visibility: ${_vm.loading ? 'visible' : 'hidden'};`),attrs:{"indeterminate":"","color":"primary"}}),(_vm.hasControl || _vm.showSave)?_c('div',{staticClass:"vp-html-editor__control"},[_c('div',{staticClass:"vp-html-editor__control__slot"},[_vm._t("control")],2),(_vm.showSave)?_c('v-btn',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'top', delay: '300' }),expression:"{ placement: 'top', delay: '300' }"}],attrs:{"content":_vm.saveBtn,"icon":"","small":"","disabled":!_vm.saveValid},on:{"click":_vm.saveValue}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.saveIcon))])],1):_vm._e()],1):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }