
import { Vue, Component, Watch, Prop, PropSync } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { App, Tab, AppOptions } from "@/graphql/API";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import Apps from "@/store/modules/Apps";
import Decisions from "@/store/modules/Decisions";
import Workspaces from "@/store/modules/Workspaces";
import FlashNotifications from "@/store/modules/FlashNotifications";
import ImageUploader from "@/components/ui/ImageUploader.vue";
import draggable from "vuedraggable";
import RichTextEditor from "@/components/ui/RichTextEditor.vue";
import { Auth, Storage } from "aws-amplify";
import { uploadMediaFile, getS3FileUrl } from "@/helpers/MediaUploadHelper";
import { v4 as uuidv4 } from "uuid";
import HTMLEditor from "@/components/ui/texteditor/HTMLEditor.vue";

const appsModule = getModule(Apps);
const decisionsModule = getModule(Decisions);
const flashModule = getModule(FlashNotifications);
const workspaceModule = getModule(Workspaces);

@Component({
    components: {
        ImageUploader,
        draggable,
        RichTextEditor,
        HTMLEditor,
    },
})
export default class AppCreator extends Vue {
    @Prop({ default: false, type: Boolean })
    isEdit!: boolean;

    @Prop()
    app!: App;

    private loading = false;

    private blankApp = {
        name: "",
        description: "",
        header: "",
        image: "",
        json: "",
    };

    private editApp = { ...this.blankApp };
    private internalImageSrc = "";
    private externalImageSrc = "";
    private userName = "";

    private editAppOptions: AppOptions = {};

    private drag = false;

    private tabList: Tab[] = [];

    private alignmentOptions = [
        {
            value: "center",
            label: "Center",
        },
        {
            value: "left",
            label: "Left",
        },
        {
            value: "right",
            label: "Right",
        },
    ];

    get fileKey(): string {
        return `${this.currentWorkspaceId ? this.currentWorkspaceId : ""}/${
            this.currentDecisionId ? this.currentDecisionId : ""
        }/assets`;
    }

    get appId(): number | null {
        return this.app ? this.app.id : null;
    }

    get currentDecisionId(): number | null {
        return decisionsModule.selectedDecisionId;
    }

    get currentWorkspaceId(): number | null {
        return workspaceModule.selectedWorkspaceId;
    }

    get title(): string {
        if (this.isEdit && this.app) {
            return `Edit App`;
        } else {
            return "Create App";
        }
    }

    get allTabs(): Tab[] {
        if (this.app) {
            return this.app.tabs;
        } else {
            return [];
        }
    }

    get tabs(): Tab[] {
        if (this.appId && appsModule.tabIndex[this.appId]) {
            return appsModule.tabIndex[this.appId];
        } else {
            return [];
        }
    }

    get dragEnabled(): boolean {
        return true;
        // return this.tabs.length > 1;
    }

    get saveActive(): boolean {
        if (this.editApp) {
            if (this.isEdit) {
                return (
                    this.editApp.name != this.app.title ||
                    this.editApp.description != this.app.description ||
                    this.editApp.header != this.app.header ||
                    this.editApp.image != this.app.image ||
                    this.optionsChanged
                );
            } else {
                return this.editApp.name != "";
            }
        } else {
            return false;
        }
    }

    get optionsChanged(): boolean {
        if (this.editApp && this.editApp.json) {
            return this.editApp.json != this.jsonOptions;
        } else {
            return Object.values(this.editAppOptions).length > 0;
        }
    }

    get jsonOptions(): string {
        return JSON.stringify(this.editAppOptions);
    }

    private async imageUrl(image: string) {
        const s3ImageUrl = await this.getS3ImageUrl(image);
        return s3ImageUrl ? s3ImageUrl.toString() : image;
    }

    private async getS3ImageUrl(imageName: string) {
        let imageURL = "";
        Storage.configure({ level: "public" });
        let userName = this.userName;
        if (!userName) {
            let authUser = await Auth.currentAuthenticatedUser();
            userName = authUser.username;
        }
        imageURL = await Storage.get(`images/${userName}/${imageName}`);
        return imageURL;
    }

    private close(): void {
        this.editApp = { ...this.blankApp };
    }

    private async createApp(): Promise<void> {
        if (this.currentDecisionId) {
            this.loading = true;
            try {
                const newApp = await appsModule.createApp({
                    decision_id: this.currentDecisionId,
                    title: this.editApp.name,
                    description: this.editApp.description,
                    header: this.editApp.header,
                    json: this.jsonOptions,
                    image: this.editApp.image,
                });
                if (newApp) {
                    this.$emit("new-app", newApp);
                    this.editApp = { ...this.blankApp };
                }
                flashModule.success({
                    message: `${this.editApp.name} created`,
                    duration: 3000,
                });
            } catch (e) {
                const err = e as GraphQLResult<any>;
                const message = err?.errors
                    ? err.errors[0].message
                    : "Something went wrong.";
                console.log("%cError:", "color: red; font-weight: bold;");
                console.log(e);
                flashModule.error({
                    message: message,
                    duration: 3000,
                });
            }
            this.loading = false;
        }
    }

    private async saveApp(): Promise<void> {
        if (this.app) {
            this.loading = true;
            try {
                await appsModule.updateApp({
                    ...this.app,
                    title: this.editApp.name,
                    description: this.editApp.description,
                    header: this.editApp.header ? this.editApp.header : "",
                    image: this.editApp.image ? this.editApp.image : "",
                    json: this.jsonOptions,
                });
                flashModule.success({
                    message: `${this.editApp.name} saved`,
                    duration: 3000,
                });
            } catch (e) {
                const err = e as GraphQLResult<any>;
                const message = err?.errors
                    ? err.errors[0].message
                    : "Something went wrong.";
                console.log("%cError:", "color: red; font-weight: bold;");
                console.log(e);
                flashModule.error({
                    message: message,
                    duration: 3000,
                });
            }
            this.loading = false;
        }
    }

    private async updateTextDescription(descriptionValue: string) {
        this.editApp.description = descriptionValue;
    }

    private editTab(tab: Tab | null): void {
        this.$emit("tab-edit", tab);
    }

    private async uploadFile(fileData: any) {
        if (this.editApp) {
            this.editApp.image = fileData.fileName;
        }
    }

    private async setAppImage(name: string) {
        this.editApp.image = name;
        this.internalImageSrc = await this.imageUrl(name);
    }

    private async setImageSrc(name: string) {
        this.internalImageSrc = await this.imageUrl(name);
    }

    /* Eslint disabled for draggable event */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private async dragChange(e: any) {
        if (e["moved"] && e.moved.element && this.app) {
            const newOrder = await this.getOrderStr(
                e.moved.newIndex,
                e.moved.oldIndex
            );
            await appsModule.updateAppTabMapping({
                app_id: this.app.id,
                tab_id: e.moved.element.id,
                order_str: newOrder,
            });
        }
    }

    private async getOrderStr(
        newIndex: number,
        oldIndex: number
    ): Promise<string> {
        let beforeTab = null;
        let afterTab = null;

        if (newIndex === 0) {
            // Tab is moved to start
            beforeTab = "0";
            afterTab = this.tabList[1]?.order_str;
        } else if (newIndex === this.tabs.length - 1) {
            afterTab = null;
            beforeTab = this.tabList[newIndex - 1]?.order_str;
        } else if (newIndex < oldIndex) {
            beforeTab = this.tabList[newIndex - 1]?.order_str;
            afterTab = this.tabList[newIndex + 1]?.order_str;
        } else if (newIndex > oldIndex) {
            beforeTab = this.tabList[newIndex - 1]?.order_str;
            afterTab = this.tabList[newIndex + 1]?.order_str;
        }

        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const mudder = require("mudder");

        return mudder.base62.mudder(beforeTab, afterTab, 1, undefined, 20)[0];
    }

    private async uploadExternalImage(): Promise<void> {
        let s3Storage = process.env.VUE_APP_PDF_STORAGE_S3_BUCKET
            ? process.env.VUE_APP_PDF_STORAGE_S3_BUCKET
            : "v3-user-upload-dev";
        if (this.editApp.image && this.editApp.image.indexOf(s3Storage) > -1) {
            return;
        } else {
            if (this.editApp.image) {
                let response = await fetch(this.editApp.image);
                let data = await response.blob();
                let metadata = {
                    type: "image/jpeg",
                };
                const fileName = uuidv4();
                let file = new File([data], fileName, metadata);

                if (file) {
                    await uploadMediaFile({
                        file: file,
                        type: "image",
                        name: fileName,
                        srcFileName: fileName,
                        key: this.fileKey,
                    });
                }

                this.editApp.image = fileName;
            }
        }
    }

    async created(): Promise<void> {
        this.onAppChange();
    }

    async mounted(): Promise<void> {
        let authUser = await Auth.currentAuthenticatedUser();
        this.userName = authUser.username;
    }

    @Watch("isEdit")
    onIsEdit(): void {
        this.onAppChange();
        this.onTabsChange();
    }

    @Watch("app", { immediate: true, deep: true })
    async onAppChange(): Promise<void> {
        if (this.isEdit && this.app) {
            this.editApp = {
                name: this.app.title,
                description: this.app.description,
                header: this.app.header,
                image: this.app.image,
                json: this.app.json,
            };

            if (this.app.json) {
                this.editAppOptions = JSON.parse(this.app.json);
            } else {
                this.editAppOptions = {};
            }
            try {
                await this.setImageSrc(this.editApp.image);
            } catch (e) {
                console.log(e);
            }
        } else {
            this.editApp = { ...this.blankApp };
            this.editAppOptions = {};
        }
    }

    @Watch("tabs", { immediate: true, deep: true })
    onTabsChange(): void {
        this.tabList = [...this.tabs];
    }
}
