import {
    Action,
    config,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "..";
import { API } from "aws-amplify";
import {
    getAllDecisions,
    getDecisions,
    getDecision,
    getPdfResult,
    getDocumentTablesResult,
    getUserDecisionCount,
} from "@/graphql/queries";
import { GraphQLResult } from "@aws-amplify/api";
import {
    CopyDecision,
    CopyDecisionInto,
    CreateDecision,
    DeleteDecision,
    GetAllDecisions,
    GetDecisions,
    GetDecision,
    GetPdfResult,
    GetDocumentTablesResult,
    PortV2ToV3,
    UpdateDecision,
    GetUserDecisionCount,
} from "@/graphql/custom";
import {
    copyDecision,
    copyDecisionInto,
    createDecision,
    deleteDecision,
    portV2ToV3,
    updateDecision,
} from "@/graphql/mutations";
import { Decision, PageSettings, Task, PdfResult, DecisionOptions, DecisionLabels, DocumentTablesResult } from "@/graphql/API";
import Vue from "vue";
import { GRAPHQL_API } from "@/graphql/GraphqlAPI";

config.rawError = true;

const name = "decisions";
if (module.hot) {
    if (store.hasModule(name)) {
        store.unregisterModule(name);
    }
}

@Module({ dynamic: true, store: store, name: name, namespaced: true })
export default class Decisions extends VuexModule {
    decisions: { [decisionId: number]: Decision } = {};
    allDecisions: { [decisionId: number]: Decision } = {};
    selectedDecisionId: number | null = null;
    dataLoading = false;
    createdDecisionId: number | null = null;
    currentSelections: PageSettings = {};
    decisionsLoading = false;
    allUserDicisionsCount = 0;

    get selectedDecision(): Decision | null {
        if (this.selectedDecisionId) {
            return this.decisions[this.selectedDecisionId];
        } else {
            return null;
        }
    }

    get selectedDecisionOptions(): DecisionOptions {
        if (this.selectedDecision && this.selectedDecision.json) {
            return JSON.parse(this.selectedDecision.json);
        } else {
            return {};
        }
    }

    get decisionsList(): Decision[] {
        return Object.values(this.decisions).sort((a, b) =>
            a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase())
        );
    }

    get allUserDecisionsList(): Decision[] {
        return Object.values(this.allDecisions).sort((a, b) =>
            a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase())
        );
    }

    get factorLabelSingular(): string {
        return "Factor";
    }

    get factorLabelPlural(): string {
        return "Factors";
    }

    get choiceLabelSingular(): string {
        if (this.selectedDecisionOptions?.labels?.singleChoice) {
            return this.selectedDecisionOptions.labels.singleChoice;
        } else {
            return "Choice";
        }
    }

    get choiceLabelPlural(): string {
        if (this.selectedDecisionOptions?.labels?.pluralChoice) {
            return this.selectedDecisionOptions.labels.pluralChoice;
        } else {
            return "Choices";
        }
    }

    get viewpointLabelSingular(): string {
        return "Viewpoint";
    }

    get viewpointLabelPlural(): string {
        return "Viewpoints";
    }

    @Mutation
    setDecisions(decisions: { [decisionId: number]: Decision }): void {
        this.decisions = decisions;
    }

    @Mutation
    setDecision(decision: Decision): void {
        Vue.set(this.decisions, decision.id, decision);
    }

    @Mutation
    removeDecision(decision: Decision): void {
        Vue.delete(this.decisions, decision.id);
    }

    @Mutation
    setSelectedDecision(decisionId: number | null): void {
        if (decisionId != null) {
            this.selectedDecisionId = decisionId;
        } else this.selectedDecisionId = null;
    }

    @Mutation
    setCreatedDecision(decisionId: number | null): void {
        if (decisionId != null) {
            this.createdDecisionId = decisionId;
        } else this.createdDecisionId = null;
    }

    @Mutation
    setLoading(loading: boolean): void {
        this.dataLoading = loading;
    }

    @Mutation
    setSetting(payload: { id: string; value: number | string | null }): void {
        if (payload.value != null) {
            Vue.set(this.currentSelections, payload.id, payload.value);
        } else {
            Vue.delete(this.currentSelections, payload.id);
        }
    }

    @Mutation
    setDecisionsLoading(loading: boolean): void {
        this.decisionsLoading = loading;
    }

    @Mutation
    setAllUserDecisionsCount(count: number): void {
        this.allUserDicisionsCount = count;
    }

    @Mutation
    setAllDecisions(decisions: { [decisionId: number]: Decision }): void {
        this.allDecisions = decisions;
    }

    @Action
    async updateLoading(loading: boolean): Promise<void> {
        this.setLoading(loading);
    }

    @Action
    async updateDecisionsLoading(loading: boolean): Promise<void> {
        this.setDecisionsLoading(loading);
    }

    @Action
    async fetchDecision(decision_id: number): Promise<Decision | null> {
        const decision = (await GRAPHQL_API.graphqlQueryRequest({
            query: getDecision,
            variables: { decision_id },
        })) as GraphQLResult<GetDecision>;
        if (decision.data && decision.data.getDecision != null) {
            return decision.data.getDecision;
        } else {
            return null;
        }
    }

    @Action
    async fetchDecisions(): Promise<any> {
        const decisions = (await GRAPHQL_API.graphqlQueryRequest({
            query: getAllDecisions,
            variables: {},
        })) as GraphQLResult<GetAllDecisions>;
        if (decisions.data && decisions.data.getAllDecisions != null) {
            const dictionary = Object.assign(
                {},
                ...decisions.data.getAllDecisions.map((x) => ({ [x.id]: x }))
            );
            this.setAllDecisions(dictionary);
            return dictionary;
        }
    }

    @Action
    async fetchWorkspaceDecisions(workspace_id: number): Promise<any> {
        if (workspace_id) {
            const decisions = (await GRAPHQL_API.graphqlQueryRequest({
                query: getDecisions,
                variables: { workspace_id },
            })) as GraphQLResult<GetDecisions>;
            if (decisions.data && decisions.data.getDecisions != null) {
                const dictionary = Object.assign(
                    {},
                    ...decisions.data.getDecisions.map((x: any) => ({
                        [x.id]: x,
                    }))
                );
                this.setDecisions(dictionary);
                return dictionary;
            }
        }
    }

    @Action
    async selectDecision(id: number | null): Promise<void> {
        this.setSelectedDecision(id);
    }

    @Action
    async createDecision(payload: {
        name: string;
        workspace_id: number | null;
        json?: string;
        description?: string;
    }): Promise<Decision | null> {
        const name = payload.name;
        const workspace_id = payload.workspace_id;
        const json = payload.json;
        const description = payload.description;
        if (name != null && workspace_id) {
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: createDecision,
                variables: {
                    name,
                    workspace_id,
                    json,
                    description,
                },
            })) as GraphQLResult<CreateDecision>;
            if (res.data && res.data.createDecision != null) {
                this.setCreatedDecision(
                    res.data.createDecision.decisions[0].id
                );
                this.setDecision(res.data.createDecision.decisions[0]);
                this.selectDecision(res.data.createDecision.decisions[0].id);
                return res.data.createDecision.decisions[0];
            }
        }
        return null;
    }

    @Action
    async copyDecision(payload: {
        source_d_id: number;
        dest_name: string;
        dest_ws_id: number;
        app_suffix: string;
    }): Promise<Decision | null> {
        const source_d_id = payload.source_d_id;
        const dest_name = payload.dest_name;
        const dest_ws_id = payload.dest_ws_id;
        const app_suffix = payload.app_suffix;
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: copyDecision,
            variables: {
                source_d_id,
                dest_name,
                dest_ws_id,
                app_suffix,
            },
        })) as GraphQLResult<CopyDecision>;
        if (res.data && res.data.copyDecision != null) {
            // this.setCreatedDecision(res.data.copyDecision.decisions[0].id);
            // this.setDecision(res.data.copyDecision.decisions[0]);
            // this.selectDecision(res.data.copyDecision.decisions[0].id);
            // return res.data.createDecision.decisions[0];
        }
        return null;
    }

    @Action
    async copyDecisionInto(payload: {
        source_d_id: number;
        dest_d_id: number;
        app_suffix: string;
    }): Promise<Decision | null> {
        const source_d_id = payload.source_d_id;
        const dest_d_id = payload.dest_d_id;
        const app_suffix = payload.app_suffix;
        const res = (await GRAPHQL_API.graphqlMutationRequest({
            query: copyDecisionInto,
            variables: {
                source_d_id,
                dest_d_id,
                app_suffix,
            },
        })) as GraphQLResult<CopyDecisionInto>;
        if (res.data && res.data.copyDecisionInto != null) {
            // this.setCreatedDecision(res.data.copyDecision.decisions[0].id);
            // this.setDecision(res.data.copyDecision.decisions[0]);
            // this.selectDecision(res.data.copyDecision.decisions[0].id);
            // return res.data.createDecision.decisions[0];
        }
        return null;
    }

    @Action
    async updateDecision(payload: {
        decision_id: number;
        name: string;
        json?: string;
        description?: string;
    }): Promise<Decision | null> {
        const name = payload.name;
        const id = payload.decision_id;
        const json = payload.json;
        const description = payload.description;
        if (name != null && id != null) {
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: updateDecision,
                variables: {
                    name,
                    id,
                    json,
                    description,
                },
            })) as GraphQLResult<UpdateDecision>;
            if (res.data && res.data.updateDecision != null) {
                this.setCreatedDecision(
                    res.data.updateDecision.decisions[0].id
                );
                this.setDecision(res.data.updateDecision.decisions[0]);
                return res.data.updateDecision.decisions[0];
            }
        }
        return null;
    }

    @Action
    async deleteDecision(id: number): Promise<Decision | null> {
        if (id != null) {
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: deleteDecision,
                variables: {
                    id,
                },
            })) as GraphQLResult<DeleteDecision>;
            if (res.data && res.data.deleteDecision != null) {
                const dec = res.data.deleteDecision.decisions[0];
                if (this.selectedDecisionId == dec.id) {
                    this.selectDecision(null);
                }
                this.removeDecision(dec);
                this.setCreatedDecision(dec.id);
                return dec;
            }
        }

        return null;
    }

    @Action
    async clearDecisions(): Promise<void> {
        this.setDecisions({});
        this.selectDecision(null);
    }

    @Action
    async addDecisions(decisions: Decision[]): Promise<void> {
        if (decisions == null || decisions[0].id == this.createdDecisionId) {
            this.setCreatedDecision(null);
            return;
        }

        decisions.forEach((decision: Decision) => {
            this.setDecision(decision);
            if (this.createdDecisionId == decision.id) {
                this.setSelectedDecision(decision.id);
                this.setCreatedDecision(null);
            }
        });
    }

    @Action
    async removeDecisions(decisions: Decision[]): Promise<void> {
        if (
            decisions == null ||
            decisions.length <= 0 ||
            this.createdDecisionId === decisions[0].id
        ) {
            this.setCreatedDecision(null);
            return;
        }

        decisions.forEach((decision: Decision) => {
            this.removeDecision(decision);
            if (this.selectedDecisionId == decision.id) {
                this.setSelectedDecision(null);
            }
        });
    }

    @Action
    async changeSetting(payload: {
        id: string;
        value: number | string | null;
    }): Promise<void> {
        this.setSetting({
            id: payload.id,
            value: payload.value,
        });
    }

    @Action
    async portV2ToV3(payload: {
        v2_decision_id: string;
        workspace_id: number | null;
    }): Promise<Task | null> {
        const v2_decision_id = payload.v2_decision_id;
        const workspace_id = payload.workspace_id;
        const stage = process.env.VUE_APP_STAGE;
        if (v2_decision_id != null && workspace_id && stage) {
            const res = (await GRAPHQL_API.graphqlMutationRequest({
                query: portV2ToV3,
                variables: {
                    v2_decision_id,
                    workspace_id,
                    stage,
                },
            })) as GraphQLResult<PortV2ToV3>;
            if (res.data && res.data.portV2ToV3 != null) {
                return res.data.portV2ToV3;
            }
        }
        return null;
    }

    @Action
    async getPdfResult(task_id: string): Promise<PdfResult | null> {
        const res = (await GRAPHQL_API.graphqlQueryRequest({
            query: getPdfResult,
            variables: {
                task_id,
            },
        })) as GraphQLResult<GetPdfResult>;
        if (res.data && res.data.getPdfResult != null) {
            return res.data.getPdfResult;
        }
        return null;
    }

    @Action
    async getDocumentTablesResult(task_id: string, stage: string): Promise<DocumentTablesResult | null> {
        const res = (await GRAPHQL_API.graphqlQueryRequest({
            query: getDocumentTablesResult,
            variables: {
                task_id,
                stage,
            },
        })) as GraphQLResult<GetDocumentTablesResult>;
        if (res.data && res.data.getDocumentTablesResult != null) {
            return res.data.getDocumentTablesResult;
        }
        return null;
    }

    @Action
    public async getUserDecisionCount(): Promise<number> {
        const userDecisionCount = (await GRAPHQL_API.graphqlQueryRequest({
            query: getUserDecisionCount,
        })) as GraphQLResult<GetUserDecisionCount>;
        if (
            userDecisionCount.data &&
            userDecisionCount.data.getUserDecisionCount != null
        ) {
            this.setAllUserDecisionsCount(userDecisionCount.data.getUserDecisionCount);
            return userDecisionCount.data.getUserDecisionCount;
        }

        return 0;
    }
}
