
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Choice, Factor, Thread, Viewpoint } from "@/graphql/API";
import Comments from "@/store/modules/Comments";
import CommentList from "./CommentList.vue";
import Workspaces from "@/store/modules/Workspaces";
import Decisions from "@/store/modules/Decisions";
import CommentEditor from "@/components/comments/CommentEditor.vue";

const commentModule = getModule(Comments);
const workspaceUsers = getModule(Workspaces);
const decisionModule = getModule(Decisions);
@Component({
    components: {
        CommentList,
        CommentEditor,
    },
})
export default class CommentThread extends Vue {
    @Prop()
    factor!: Factor;

    @Prop()
    choice!: Choice;

    @Prop()
    viewpoint!: Viewpoint;

    @Prop()
    decisionId!: number;

    private show = false;
    private changeLoading = false;
    private showNewThread = false;
    private newThreadName = "";
    private showDeleteWarning: number | null = null;

    private loading = false;

    mounted() {
        // this.onChangeCommentKey();
    }

    get factorLabelSingular(): string {
        return decisionModule.factorLabelSingular;
    }

    get choiceLabelSingular(): string {
        return decisionModule.choiceLabelSingular;
    }

    get viewpointLabelSingular(): string {
        return decisionModule.viewpointLabelSingular;
    }

    get keyedThreads(): { [id: string]: Thread[] } {
        return commentModule.keyedThreads;
    }

    get threads(): Thread[] {
        return this.keyedThreads[
            `${this.viewpoint.id ?? "-1"}-${this.choice.id}-${this.factor.id}`
        ];
    }

    get commentKey(): string {
        return `${this.viewpoint.id ?? "-1"}-${this.choice.id}-${
            this.factor.id
        }`;
    }

    get thread(): Thread | null {
        return commentModule.keyedThreads[this.commentKey] &&
            commentModule.keyedThreads[this.commentKey][0]
            ? commentModule.keyedThreads[this.commentKey][0]
            : null;
    }

    /*
    @Watch("commentKey")
    async onChangeCommentKey(): Promise<void> {
        if (this.keyedThreads[this.commentKey] == null) {
            this.createThread();
        }
    }
    */

    private async createThread(): Promise<void> {
        await commentModule.createThread({
            factor_id: this.factor.id,
            choice_id: this.choice.id ?? undefined,
            viewpoint_id: this.viewpoint.id,
            name: "Comment Thread",
            row_id: "",
        });
        this.newThreadName = "";
    }

    private async deleteThread(id: number): Promise<void> {
        await commentModule.deleteThread(id);
    }
}
