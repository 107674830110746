var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"model-editor vp-flex-grow-container"},[(_vm.backFactor)?_c('v-btn',{staticClass:"btn-plain btn-truncate btn-full-width",on:{"click":function($event){return _vm.$emit('toggle-factor', { item: _vm.backFactor, multiple: false })}}},[_c('v-icon',[_vm._v("mdi-chevron-left")]),_c('span',{staticClass:"text-truncate"},[_vm._v(" Back to "+_vm._s(_vm.backFactor.name))])],1):_vm._e(),(_vm.createNew && _vm.editable)?_c('div',{staticClass:"model-editor__tabs"},[(
                _vm.selectedFactor &&
                (_vm.selectedFactor.is_table || _vm.selectedFactor.is_group)
            )?_c('v-btn',{staticClass:"btn-plain btn-truncate btn-full-width",on:{"click":function($event){return _vm.$emit('switch-to-edit')}}},[_c('v-icon',[_vm._v("mdi-chevron-left")]),_c('span',{staticClass:"text-truncate"},[_vm._v(" Back to "+_vm._s(_vm.selectedFactor.name)+" ")])],1):_vm._e(),(_vm.view == 'ModelView')?_c('FactorCreator',{attrs:{"isEdit":false,"isColumn":_vm.selectedFactor && _vm.selectedFactor.is_table,"parentId":_vm.selectedFactor &&
                (_vm.selectedFactor.is_group || _vm.selectedFactor.is_table)
                    ? _vm.selectedFactor.id
                    : _vm.rootFactorId,"ordering":_vm.nextOrderStr}}):(_vm.view == 'ModelImportFrameworkView')?_c('DecisionImport'):(_vm.view == 'ModelExtractFromDocument')?_c('FactorCreator',{attrs:{"isEdit":false,"isExtractFromDocument":true,"isColumn":_vm.selectedFactor && _vm.selectedFactor.is_table,"parentId":_vm.selectedFactor &&
                (_vm.selectedFactor.is_group || _vm.selectedFactor.is_table)
                    ? _vm.selectedFactor.id
                    : _vm.rootFactorId,"ordering":_vm.nextOrderStr}}):_vm._e()],1):(_vm.selectedFactor)?_c('div',[_c('FactorCreator',{attrs:{"factorEdit":_vm.selectedFactor,"isEdit":true,"editProps":_vm.selectedFactor.id != _vm.rootId,"editable":_vm.editable,"isColumn":_vm.selectedFactor.is_column,"add-only":_vm.addOnly,"parentId":_vm.selectedFactor.parent_id},on:{"new-factor":function($event){return _vm.$emit('switch-to-new')},"toggle-factor":_vm.sendToggledFactor}})],1):(_vm.selectedFactors.length > 1)?_c('div',{staticClass:"vp-padding-x-3 vp-padding-y-3"},[_c('ul',_vm._l((_vm.selectedFactors),function(factor,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(factor.name)+" ")])}),0)]):_c('div',{staticClass:"text-center mt-2"},[_vm._v("Select a factor to view info")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }