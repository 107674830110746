import { API, graphqlOperation } from "aws-amplify";
import {
    getAllUsers,
    getDecisions,
    getUser,
    getWorkspaceUsers,
} from "@/graphql/queries";
import { APIClass, GraphQLResult } from "@aws-amplify/api";
import {
    CustomGraphQLError,
    CustomGraphQLResult,
    GetAllUsers,
    GetDecisions,
    GetUser,
    GetWorkspaceUsersL,
    ReportErrorL,
} from "@/graphql/custom";
import { Decision, User, UserPermission } from "@/graphql/API";
import { DocumentNode, GraphQLError, GraphQLInputType } from "graphql";
import { getModule } from "vuex-module-decorators";
import FlashNotifications from "@/store/modules/FlashNotifications";
import { reportErrorL } from "./mutations";

const flashModule = getModule(FlashNotifications);

export class GraphqlAPI {
    public async graphqlMutationRequest(payload: {
        query: string;
        variables: Record<string, unknown>;
        error?: boolean;
    }): Promise<unknown> {
        const mutation = payload.query;
        const variables = payload.variables;
        const fullRequest = graphqlOperation(mutation, { ...variables });
        try {
            const req = (await API.graphql(
                fullRequest
            )) as GraphQLResult<unknown>;
            return req;
        } catch (e: any) {
            const errorMessage = e as CustomGraphQLResult;
            if (errorMessage.errors) {
                for (const err of errorMessage.errors) {
                    console.log(err.message);
                    flashModule.error({ message: err.message, duration: 3000 });
                    if (
                        process.env.VUE_APP_STAGE != "dev" &&
                        err.errorType != "RDSHttp:ExecutionTimeoutException"
                    ) {
                        this.sendErrorReport(
                            err.errorType
                                ? err.errorType
                                : "Undefined endpoint",
                            err.message,
                            true,
                            JSON.stringify(mutation),
                            JSON.stringify(fullRequest)
                        );
                    }
                }
            }
            const res: GraphQLResult = {
                data: undefined,
            };
            if (payload.error) {
                throw e;
            }
            return res;
        }
    }

    public async graphqlQueryRequest(payload: {
        query: string;
        variables?: Record<string, unknown>;
        error?: boolean;
    }): Promise<unknown> {
        const query = payload.query;
        const variables = payload.variables;
        const fullRequest = graphqlOperation(query, { ...variables });

        try {
            const req = (await API.graphql(
                fullRequest
            )) as GraphQLResult<unknown>;
            return req;
        } catch (e: any) {
            const errorMessage = e as CustomGraphQLResult;
            if (errorMessage.errors) {
                for (const err of errorMessage.errors) {
                    flashModule.error({ message: err.message, duration: 3000 });
                    if (process.env.VUE_APP_STAGE != "dev") {
                        this.sendErrorReport(
                            err.errorType
                                ? err.errorType
                                : "Undefined endpoint",
                            err.message,
                            false,
                            JSON.stringify(query),
                            JSON.stringify(fullRequest)
                        );
                    }
                }
            }
            throw e;
            const res: GraphQLResult = {
                data: undefined,
            };
            if (payload.error) {
                throw e;
            }
            return res;
        }
    }

    public async sendErrorReport(
        e_type: string,
        message: string,
        mutation: boolean,
        field_name: string,
        full_input: string
    ): Promise<boolean | null> {
        const req = (await API.graphql({
            query: reportErrorL,
            variables: { e_type, message, mutation, field_name, full_input },
        })) as GraphQLResult<ReportErrorL>;
        const reportRecieved = req.data?.reportErrorL;
        if (reportRecieved) {
            return reportRecieved;
        }
        return null;
    }

    public async fetchDecisions(workspace_id: number): Promise<Decision[]> {
        const decisions = (await API.graphql({
            query: getDecisions,
            variables: { workspace_id },
        })) as GraphQLResult<GetDecisions>;
        if (decisions.data && decisions.data.getDecisions != null) {
            return decisions.data.getDecisions;
        }

        return [];
    }

    public async getUser(email: string): Promise<User | null> {
        const userPermissions = (await API.graphql({
            query: getUser,
            variables: { email },
        })) as GraphQLResult<GetUser>;
        if (userPermissions.data && userPermissions.data.getUser != null) {
            return userPermissions.data.getUser;
        }

        return null;
    }

    public async getWorkspaceUsers(
        workspace_id: number
    ): Promise<UserPermission[]> {
        const userPermissions = (await API.graphql({
            query: getWorkspaceUsers,
            variables: { workspace_id },
        })) as GraphQLResult<GetWorkspaceUsersL>;
        if (
            userPermissions.data &&
            userPermissions.data.getWorkspaceUsersL != null
        ) {
            return userPermissions.data.getWorkspaceUsersL;
        }

        return [];
    }

    public async getAllUsers(): Promise<User[]> {
        const userPermissions = (await API.graphql({
            query: getAllUsers,
            variables: {},
        })) as GraphQLResult<GetAllUsers>;
        if (userPermissions.data && userPermissions.data.getAllUsers != null) {
            return userPermissions.data.getAllUsers;
        }

        return [];
    }
}

export const GRAPHQL_API = new GraphqlAPI();
