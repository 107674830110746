
import { Component, Prop, Vue, PropSync, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Choice } from "@/graphql/API";
import Decisions from "@/store/modules/Decisions";
import Choices from "@/store/modules/Choices";
import DialogCardTitle from "@/components/ui/DialogCardTitle.vue";
import VpDialog from "@/components/ui/VpDialog.vue";
import FlashNotifications from "@/store/modules/FlashNotifications";
import RichTextEditor from "@/components/ui/RichTextEditor.vue";
import HTMLEditor from "@/components/ui/texteditor/HTMLEditor.vue";

const choicesModule = getModule(Choices);
const decisionsModule = getModule(Decisions);
const flashNotificationsModule = getModule(FlashNotifications);
@Component({
    components: {
        DialogCardTitle,
        VpDialog,
        RichTextEditor,
        HTMLEditor,
    },
})
export default class ChoiceCreator extends Vue {
    @PropSync("open", Boolean)
    syncedOpen!: boolean;

    @Prop({ default: true, type: Boolean })
    showIcon!: boolean;

    @Prop()
    choice!: Choice;

    @Prop({ default: false, type: Boolean })
    isEdit!: boolean;

    @Prop({ default: false, type: Boolean })
    showDelete!: boolean;

    @Prop()
    btnText!: string;

    @Prop({ default: false, type: Boolean })
    isCopy!: boolean;

    @Prop({ default: true, type: Boolean })
    dialog!: boolean;

    @Prop({ default: false, type: Boolean })
    isDelete!: boolean;

    private choiceName = "";
    private deleteMode = false;
    private description = "";
    private loading = false;

    get dialogTitle(): string {
        if (this.isCopy) {
            return `Duplicate ${this.choiceLabelSingular}`;
        } else if (this.isEdit) {
            return `Edit ${this.choiceLabelSingular}`;
        } else {
            return `Create ${this.choiceLabelSingular}`;
        }
    }

    get selectedDecisionId(): number | null {
        return decisionsModule.selectedDecisionId;
    }

    get choiceLabelSingular(): string {
        return getModule(Decisions).choiceLabelSingular;
    }

    get deleteToggle(): boolean {
        return this.deleteMode || this.isDelete;
    }

    private closeDialog(): void {
        this.syncedOpen = false;
        this.choiceName = "";
        this.deleteMode = false;
        this.$emit("dialog-close");
    }

    private async saveChoice(): Promise<void> {
        this.loading = true;
        if (this.selectedDecisionId && this.choiceName && this.choice) {
            this.$emit("loading-start");
            try {
                await choicesModule.updateChoice({
                    name: this.choiceName,
                    choiceId: this.choice.id,
                    description: this.description,
                });
                flashNotificationsModule.success({
                    message: `${this.choiceName} was successfully saved`,
                    duration: 3000,
                });
            } catch (err) {
                console.log(err);
                flashNotificationsModule.error({
                    message: "Something went wrong. Please try again",
                    duration: 3000,
                });
            }
            this.$emit("loading-end");
            this.$emit("choice-saved");
        }
        this.loading = false;
        this.closeDialog();
    }

    private async createChoice(): Promise<void> {
        this.loading = true;
        if (this.selectedDecisionId && this.choiceName) {
            this.$emit("loading-start");
            if (this.isCopy && this.choice) {
                let choices = await this.copyChoice();
                this.$emit("new-choices", [choices]);
            } else {
                let choices = await Promise.all(
                    this.choiceName.split(/\r?\n/).map(async (name) => {
                        const newChoice = await this.newChoice(name);
                        return newChoice;
                    })
                );
                this.$emit("new-choices", choices);
            }
            this.choiceName = "";
            this.$emit("loading-end");
        }
        this.closeDialog();
        this.loading = false;
    }

    private async newChoice(name: string): Promise<Choice | null> {
        if (this.selectedDecisionId) {
            let msg = `${name} was successfully created`;
            try {
                let createdChoice = await choicesModule.createChoiceL({
                    name: name,
                    decision_id: this.selectedDecisionId,
                    description: this.description,
                });
                flashNotificationsModule.success({
                    message: msg,
                    duration: 3000,
                });
                return createdChoice;
            } catch (err) {
                console.error(err);
                flashNotificationsModule.error({
                    message: "Something went wrong. Please try again",
                    duration: 3000,
                });
                return null;
            }
        } else {
            return null;
        }
    }

    private async copyChoice(): Promise<Choice | null> {
        let msg = `${this.choiceName} was successfully created`;
        try {
            let createdChoice = await choicesModule.copyChoice({
                name: this.choiceName,
                id: this.choice.id,
                description: this.description,
            });
            flashNotificationsModule.success({
                message: msg,
                duration: 3000,
            });
            return createdChoice;
        } catch (err) {
            console.error(err);
            flashNotificationsModule.error({
                message: "Something went wrong. Please try again",
                duration: 3000,
            });
            return null;
        }
    }

    private async deleteChoice(): Promise<void> {
        this.$emit("loading-start");
        this.loading = true;
        try {
            await choicesModule.deleteChoice(this.choice.id);
            flashNotificationsModule.success({
                message: `${this.choiceName} was successfully deleted`,
                duration: 3000,
            });
        } catch (err) {
            console.error(err);
            flashNotificationsModule.error({
                message: "Something went wrong. Please try again",
                duration: 3000,
            });
        }
        this.loading = false;
        this.$emit("delete-choice");
        this.$emit("loading-end");
        this.closeDialog();
    }

    mounted(): void {
        this.onChoiceChange();
    }

    @Watch("isEdit")
    onIsEdit(): void {
        this.onChoiceChange();
    }

    @Watch("choice", { immediate: true, deep: true })
    onChoiceChange(): void {
        if (this.isEdit && this.choice) {
            this.choiceName = this.choice.name;
            this.description = this.choice.description;
        } else {
            this.choiceName = "";
            this.description = "";
        }
    }

    private updateTextDescription(val: string): void {
        this.description = val;
    }
}
