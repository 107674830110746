var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"vp-compare__row__item",class:{
        'vp-compare__row__item--border-x': _vm.borderX && !_vm.full,
        'vp-compare__row__item--border-y': _vm.borderY,
        'vp-compare__row__item--full': _vm.full,
        'vp-compare__row__item--cell': _vm.tableCell,
        'vp-compare__row__item--highlight': _vm.highlight,
    },style:(_vm.full ? '' : `width: ${_vm.columnWidth}px;`)},[_c('div',{staticClass:"vp-compare__row__item__wrapper"},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }