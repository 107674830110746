
import { Vue, Component, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { mixins } from "vue-class-component";
import FactorAiBase from "@/components/mixins/FactorAiBase";
import Factors from "@/store/modules/Factors";
import { createFactorObj } from "@/helpers/FactorAiHelp";
import FlashNotifications from "@/store/modules/FlashNotifications";
import { FactorAI, Factor, Task, TableTitle } from "@/graphql/API";
import { OpenAiOptionsResult, OpenAiResult } from "@/graphql/custom";
import ViewWrapper from "@/components/ui/ViewWrapper.vue";
import DocumentPrompt from "@/components/model/DocumentExtract/DocumentPrompt.vue";
import RecursiveDocumentFactors from "@/components/model/DocumentExtract/RecursiveDocumentFactors.vue";
import DocumentFactorEditor from "@/components/model/DocumentExtract/DocumentFactorEditor.vue";
import VpDialog from "@/components/ui/VpDialog.vue";
import FactorToggleGroup from "@/components/model/FactorToggle/FactorToggleGroup.vue";
import AiFactorCreator from "@/components/model/Ai/AiFactorCreator.vue";
import AccessDenied from "@/components/users/AccessDenied.vue";

const modelModule = getModule(Factors);
const flashNotificationsModule = getModule(FlashNotifications);

@Component({
    name: "FactorDocumentView",
    components: {
        ViewWrapper,
        DocumentPrompt,
        DocumentFactorEditor,
        RecursiveDocumentFactors,
        VpDialog,
        FactorToggleGroup,
        AiFactorCreator,
        AccessDenied,
    },
})
export default class FactorDocumentView extends mixins(FactorAiBase) {
    private testing = false;
    private promtOpen = false;

    private sideTrigger = "";
    private loading = false;
    private promptLoading = false;
    private sideIndex = "prompt";
    private mode = "edit";

    private catPromptOpen = false;
    private deleteOpen = false;
    private resetOpen = false;
    private errorOpen = false;
    private confirmOpen = false;
    private factorSelectorOpen = false;

    private selectedIds: number[] = [];

    private showHeader = true;
    private actionsMenu = false;

    private toggledFactors: number[] = [];

    private editMode = true;

    private catFactors: {
        factor: Factor;
        options: boolean;
        group: boolean;
        boolean: boolean;
    }[] = [];

    get hasFactors(): boolean {
        if (
            this.factorTree[this.rootFactorId] &&
            this.factorTree[this.rootFactorId].length
        ) {
            return true;
        } else {
            return false;
        }
    }

    get selectedId(): number | null {
        if (this.selectedIds.length) {
            return this.selectedIds[0];
        } else {
            return null;
        }
    }

    get selectedFactors(): Factor[] {
        if (this.selectedIds.length) {
            return this.selectedIds.map((id) => this.factorMap[id]);
        } else {
            return [];
        }
    }

    get selectedFactor(): Factor | null {
        if (this.selectedFactors.length) {
            return this.selectedFactors[0];
        } else {
            return null;
        }
    }

    get loadingOverlay(): boolean {
        return this.loading || this.promptLoading;
    }

    get addDisabled(): boolean {
        if (this.selectedGroup && this.selectedGroup.length) {
            return false;
        } else {
            return true;
        }
    }

    get rootFactorId(): number {
        return modelModule.factorRoot?.id ?? -1;
    }

    /* GeneratedFactors is actually type TableTitle[] when emited to this component */
    private async populateFactorsTable(payload: {
        factors: TableTitle;
        parent?: number;
    }): Promise<void> {
        let generatedResults = JSON.parse(payload.factors.name);
        console.log(payload.factors);
        console.log(generatedResults);

        let factorList: any = [];

        await Promise.all(
            Object.values(generatedResults).map(
                async (factors: any, index: number) => {
                    let list: FactorAI[] = await this.populateFactorsList({
                        generatedResults: factors,
                        parent: payload.parent,
                    });
                    if(list.length) {
                        factorList.push(list);
                    }

                    // console.log(factorList2.concat(factorList));
                    console.log(list);
                }
            )
        );

        let factorListMerged: FactorAI[] = Array.prototype.concat.apply([], factorList);
        console.log("factorListMerged", factorListMerged);

        // Object.values(generatedResults).map()

        const factors = await this.getGeneratedFactors(
            factorListMerged,
            payload.parent
        );

        if (factors.length > 0) {
            this.setFactorTree(factors);
            this.toggleCatPrompt(factors);
        } else {
            this.errorOpen = true;
            setTimeout(() => {
                this.errorOpen = false;
            }, 3000);
        }
    }

    //  /* GeneratedFactors is actually type TableTitle[] when emited to this component */
    // private async populateFactorsTable(payload: {
    //     factors: TableTitle;
    //     parent?: number;
    // }): Promise<void> {
    //     let generatedResults = JSON.parse(payload.factors.name);
    //     console.log(payload.factors);
    //     console.log(generatedResults);
    //     let factorList: FactorAI[] = await this.populateFactorsList({
    //         generatedResults: generatedResults,
    //         parent: payload.parent,
    //     });

    //     const factors = await this.getGeneratedFactors(
    //         factorList,
    //         payload.parent
    //     );

    //     if (factors.length > 0) {
    //         this.setFactorTree(factors);
    //         this.toggleCatPrompt(factors);
    //     } else {
    //         this.errorOpen = true;
    //         setTimeout(() => {
    //             this.errorOpen = false;
    //         }, 3000);
    //     }
    // }

    private async populateFactorsList(payload: {
        generatedResults: any;
        parent?: number;
    }): Promise<FactorAI[]> {
        let factorList: FactorAI[] = [];
        // generatedResults  - array of parents factors
        // level: Number,
        // number: Number,
        // children: [Array of objects (children Factors)],
        // text: [Array of Factors where value[0] - factor name and rest is description]

        let parentIds = await this.getRandomIds(
            payload.generatedResults.length,
            this.factorIds
        );
        let loopResults = async (children: any, parentId: string) => {
            const ids = await this.getRandomIds(
                children.length,
                this.factorIds
            );

            await Promise.all(
                children.map(async (element: any, index: number) => {
                    let childFactorType =
                        element?.value?.children?.length > 0
                            ? "group"
                            : "label";
                    let factor: FactorAI = {
                        id: ids[index].toString(),
                        name: element.value.text[0].value[0].content,
                        description: this.populateDescription(
                            element.value.text[0]
                        ),
                        type: childFactorType,
                        parent_id: Number(parentId),
                    };
                    factorList.push(factor);

                    if (element?.value?.children?.length > 0) {
                        await loopResults(element.value.children, factor.id);
                    }
                })
            );
        };
        await Promise.all(
            payload.generatedResults.map(async (factor: any, index: number) => {
                console.log(factor);
                if (factor) {
                    let factorType =
                        factor?.value?.children.length > 0 ? "group" : "label";
                    let parentFactor: FactorAI = {
                        id: parentIds[index].toString(),
                        name: factor.value.text[0].value[0].content,
                        description: this.populateDescription(
                            factor.value.text[0]
                        ),
                        type: factorType,
                        parent_id: this.rootFactorId,
                    };

                    factorList.push(parentFactor);

                    if (factor.value.children.length > 0) {
                        await loopResults(
                            factor.value.children,
                            parentFactor.id
                        );
                    }
                }
            })
        );

        return factorList;
    }

    private populateDescription(context: any): string {
        let description = "";
        context.value.forEach((element: any) => {
            if (element.type === "Context") {
                description = description + element.content;
            }
        });

        let keywords = "\n Keywords: " + context.keywords.toString();

        return description + keywords;
    }

    // private async populateFactorsList(payload: {
    //     generatedResults: any;
    //     parent?: number;
    // }): Promise<FactorAI[]> {
    //     let factorList: FactorAI[] = [];
    //     let parentIds = await this.getRandomIds(payload.generatedResults.length, this.factorIds);
    //     let loopResults = async (content: any, parentId: string) => {
    //         const ids = await this.getRandomIds(content.length, this.factorIds);

    //         await Promise.all(
    //             content.map(async (element: any, index: number) => {
    //                 let childFactorType = element?.content?.length > 0 ? "group" : "label";
    //                 let factor: FactorAI = {
    //                     id: ids[index].toString(),
    //                     name: element?.text?.length > 0 ? element.text : element,
    //                     description: "",
    //                     type: childFactorType,
    //                     parent_id: Number(parentId),
    //                 };
    //                 factorList.push(factor);

    //                 if (element?.content?.length > 0) {
    //                     await loopResults(element.content, factor.id);
    //                 }
    //             })
    //         );
    //     };
    //     await Promise.all(
    //         payload.generatedResults.map(async (factor: any, index: number) => {
    //             console.log(factor);
    //             let factorType = factor.content.length > 0 ? "group" : "label";
    //             let parentFactor: FactorAI = {
    //                 id: parentIds[index].toString(),
    //                 name: factor.text,
    //                 description: "",
    //                 type: factorType,
    //                 parent_id: this.rootFactorId,
    //             };

    //             factorList.push(parentFactor);

    //             if (factor.content.length > 0) {
    //                 console.log("factor.content", factor.content);
    //                 await loopResults(factor.content, parentFactor.id);
    //             }
    //         })
    //     );

    //     return factorList;
    // }

    /* GeneratedFactors is actually type OpenAiResult[] when emited to this component */
    private async populateFactorsTableAi(payload: {
        factors: OpenAiResult[];
        parent?: number;
    }): Promise<void> {
        const factors = await this.getGeneratedFactors(
            payload.factors.map((factor) => {
                return {
                    id: factor.factor_id.toString(),
                    name: factor.name,
                    description: factor.description,
                    type: factor.type,
                    parent_id: factor.parent_id,
                };
            }),
            payload.parent
        );
        if (factors.length > 0) {
            this.setFactorTree(factors);
            this.toggleCatPrompt(factors);
        } else {
            this.errorOpen = true;
            setTimeout(() => {
                this.errorOpen = false;
            }, 3000);
        }
    }

    private toggleCatPrompt(factors: Factor[]): void {
        this.catFactors = factors
            .filter(
                (factor) =>
                    factor.value_type == "category" || factor.type == "category"
            )
            .map((factor) => {
                return {
                    factor: factor,
                    options: true,
                    group: false,
                    boolean: false,
                };
            });
        if (this.catFactors.length > 0) {
            this.catPromptOpen = true;
        }
    }

    private setFactorTree(factors: Factor[]): void {
        factors.forEach((factor) => {
            const parentId = factor.parent_id != null ? factor.parent_id : -1;
            Vue.set(this.factorMap, factor.id, factor);
            if (this.factorTree[parentId]) {
                this.factorTree[parentId].push(factor);
            } else {
                Vue.set(this.factorTree, parentId, [factor]);
            }
        });
    }

    private async getGeneratedFactors(
        generatedFactors: FactorAI[],
        parentId = -1
    ): Promise<Factor[]> {
        const ids = await this.getRandomIds(
            generatedFactors.length,
            this.factorIds
        );
        return await Promise.all(
            generatedFactors.map(async (factor, index) => {
                return await createFactorObj(
                    factor,
                    parentId,
                    this.selectedDecisionId ? this.selectedDecisionId : -1,
                    Number(factor.id),
                    `${index}`,
                    this.defaultFactorSetId
                );
            })
        );
    }

    private async getRandomIds(
        length: number,
        ids: number[]
    ): Promise<number[]> {
        let newIds: number[] = [];
        for (let i = 0; i < length; i++) {
            let id = 0;
            for (;;) {
                id = Math.floor(Math.random() * 1000000);
                if (!ids.includes(id) && !newIds.includes(id) && id != 0) {
                    break;
                }
            }
            newIds.push(id);
        }
        return newIds;
    }

    private async initGenerateOptions(factor: Factor): Promise<void> {
        if (factor) {
            this.loading = true;
            if (this.topicArea && this.creativity) {
                await this.generateOptions([factor]);
            } else {
                this.promtOpen = true;
            }
            this.loading = false;
        }
    }

    private async setAiPromt(): Promise<void> {
        if (this.selectedFactor) {
            this.loading = true;
            if (this.topicArea && this.creativity) {
                await this.generateOptions([this.selectedFactor]);
            } else {
                this.promtOpen = true;
            }
            this.promtOpen = false;
            this.loading = false;
        }
    }

    private async generateConvert(): Promise<void> {
        this.loading = true;
        this.catPromptOpen = false;
        await Promise.all([
            this.convertToGroup(
                this.catFactors
                    .filter((item) => item.group)
                    .map((item) => item.factor)
            ),
            this.generateOptions(
                this.catFactors
                    .filter((item) => item.options)
                    .map((item) => item.factor)
            ),
            this.generateBoolean(
                this.catFactors
                    .filter((item) => item.boolean)
                    .map((item) => item.factor)
            ),
        ]);
        this.loading = false;
    }

    private async convertSingleGroup(factors: Factor[]): Promise<void> {
        this.loading = true;
        await this.convertToGroup(factors);
        this.loading = false;
    }

    /* Function for Chris to use to create groups prompt */
    private async convertToGroup(factors: Factor[]): Promise<void> {
        const factorGroupsInput: OpenAiResult[] = [];
        const categoryFactors = [
            ...factors.filter(
                (factor) =>
                    factor.value_type === "category" &&
                    this.optionTree[factor.id] != null &&
                    this.optionTree[factor.id].length > 0
            ),
        ];
        const nonCatFactors = [
            ...factors.filter(
                (factor) =>
                    factor.value_type != "category" ||
                    this.optionTree[factor.id] == null ||
                    this.optionTree[factor.id].length === 0
            ),
        ];
        /* For CatFactors with enums make options factors in group */
        if (categoryFactors.length > 0) {
            await this.doCategoricalToGroupConvert(factors);
        }
        /* For nonCatFactors use secondlevelprompt endpoint */
        if (nonCatFactors.length > 0) {
            await Promise.all(
                factors.map(async (factor) => {
                    this.changeToGroup(this.factorMap[factor.id]);
                    factorGroupsInput.push({
                        task_id: "10000",
                        factor_id: factor.id,
                        name: factor.name,
                        description: factor.description
                            ? factor.description
                            : "",
                        type: factor.value_type,
                    });
                })
            );
            const res = await modelModule.getGeneratedSecondLevelFactors({
                groups: factorGroupsInput,
                topic_area: this.topicArea,
                creativity: this.creativity,
                persona: this.persona,
            });
            // await this.getGeneratedFactorsResult(res, 0);
        }
    }

    private async doCategoricalToGroupConvert(
        factors: Factor[]
    ): Promise<void> {
        const optionsToFactors: OpenAiResult[] = [];
        factors.map((factor) => {
            this.changeToGroup(factor);
            const options = this.optionTree[factor.id];
            const optionsFacs: OpenAiResult[] = options.map(
                (category, index) => {
                    return {
                        factor_id: index,
                        name: category,
                        type: "categorical",
                        description: category,
                        parent_id: factor.id,
                        task_id: "1000",
                    };
                }
            );
            optionsToFactors.splice(0, 0, ...optionsFacs);
            Vue.delete(this.optionTree, factor.id);
        });
        this.populateFactorsTableAi({ factors: optionsToFactors });
    }

    private convertToMultipleChoice(factors: Factor[]): void {
        const catOptions: OpenAiOptionsResult[] = [];
        for (const factor in factors) {
            const multipleChoices = this.factorTree[factors[factor].id]?.map(
                (child) => child.name
            );
            const catVal: OpenAiOptionsResult = {
                cat_id: parseInt(factor),
                task_id: "",
                category_name: factors[factor].name,
                options: JSON.stringify(multipleChoices),
            };
            catOptions.push(catVal);
        }
        this.populateCatOptions(factors, catOptions);
        factors.map((factor) => this.changeToMultipleChoice(factor));
    }

    private async generateOptions(factors: Factor[]): Promise<void> {
        if (factors.length > 0) {
            const categoricalFactors = JSON.stringify(
                factors.map((factor) => factor.name)
            );

            try {
                const task = await modelModule.getGeneratedCategoryOptions({
                    topic_area: this.topicArea,
                    creativity: this.creativity,
                    categorical_factors: categoricalFactors,
                });
                let options: OpenAiOptionsResult[] | null | undefined = [];
                if (this.testing) {
                    options = this.testOptions;
                } else {
                    options = await this.getGeneratedCategoryOptions(task, 0);
                }

                if (options && options.length) {
                    await this.populateCatOptions(factors, options);
                }
            } catch (error) {
                flashNotificationsModule.error({
                    message: `Unexpected error: ${error}`,
                    duration: 5000,
                });
                console.log(
                    "%cError generateOptions():",
                    "color: red; font-weight: bold;"
                );
                console.log(error);
            }
        }
    }

    private async generateBoolean(factors: Factor[]): Promise<void> {
        if (factors.length > 0) {
            await Promise.all(
                factors.map(async (factor) => {
                    Vue.set(this.optionTree, factor.id, ["true", "false"]);
                })
            );
        }
    }

    private async getGeneratedCategoryOptions(
        task: Task | null,
        requests: number
    ): Promise<OpenAiOptionsResult[] | null | undefined> {
        if (task) {
            try {
                let results =
                    await modelModule.getGeneratedCategoryOptionsResults({
                        task_id: task.id,
                    });
                if (results) {
                    let taskStatus = results.task.status;
                    if (taskStatus == "SUCCESS") {
                        return results.result;
                    } else if (taskStatus == "PENDING" && requests <= 5) {
                        return await new Promise<
                            OpenAiOptionsResult[] | null | undefined
                        >((resolve) => {
                            setTimeout(() => {
                                this.getGeneratedCategoryOptions(
                                    task,
                                    requests + 1
                                ).then(
                                    (
                                        values:
                                            | OpenAiOptionsResult[]
                                            | null
                                            | undefined
                                    ) => {
                                        resolve(values);
                                    }
                                );
                            }, 5000);
                        }).then(
                            (
                                values: OpenAiOptionsResult[] | null | undefined
                            ) => {
                                return values;
                            }
                        );
                    } else if (taskStatus == "FAILURE") {
                        flashNotificationsModule.error({
                            message: `Unexpected error, task has status FAILURE`,
                            duration: 5000,
                        });
                        return null;
                    } else {
                        flashNotificationsModule.warning({
                            message: `It looks like the process is taking too long. Try one more time.`,
                            duration: 10000,
                        });
                        return null;
                    }
                } else {
                    flashNotificationsModule.warning({
                        message: `It looks like the process is taking too long. Try one more time.`,
                        duration: 10000,
                    });
                    return null;
                }
            } catch (error) {
                flashNotificationsModule.error({
                    message: `Unexpected error: ${error}`,
                    duration: 10000,
                });
                console.log(
                    "%cError getGeneratedCategoryOptions():",
                    "color: red; font-weight: bold;"
                );
                console.log(error);
                return null;
            }
        } else {
            return null;
        }
    }

    private async populateCatOptions(
        factors: Factor[],
        options: OpenAiOptionsResult[]
    ): Promise<void> {
        await Promise.all(
            options.map(async (option) => {
                const factor = factors.find(
                    (factor) => factor.name == option.category_name
                );
                if (factor && option.options) {
                    Vue.set(
                        this.optionTree,
                        factor.id,
                        JSON.parse(option.options)
                    );
                }
            })
        );
    }

    private toggleFactor(factor: Factor): void {
        this.editMode = true;
        if (factor) {
            if (this.selectedIds.includes(factor.id)) {
                this.mode = "info";
                this.sideTrigger = "sidebar-close";
                this.selectedIds.splice(this.selectedIds.indexOf(factor.id), 1);
            } else {
                this.mode = "edit";
                this.selectedIds = [factor.id];
                this.sideTrigger = `edit-${factor.id}`;
            }
        }
    }

    private closeCatPrompt(): void {
        this.catPromptOpen = false;
        this.catFactors = [];
    }

    private changeToGroup(factor: Factor): void {
        if (factor) {
            Vue.set(this.factorMap[factor.id], "value_type", "group");
            Vue.set(this.factorMap[factor.id], "is_group", true);
            Vue.set(
                this.factorMap[factor.id],
                "index_rule_id",
                this.orgIdToDefaultRuleMap[-9].id
            );
            Vue.set(
                this.factorMap[factor.id],
                "score_rule_id",
                this.orgIdToDefaultRuleMap[-8].id
            );
        }
    }

    private changeToMultipleChoice(factor: Factor): void {
        if (factor.is_group || factor.value_type === "group") {
            Vue.delete(this.factorTree, factor.id);
        }
        if (factor) {
            Vue.set(this.factorMap[factor.id], "value_type", "category");
            Vue.set(this.factorMap[factor.id], "is_group", false);
            Vue.set(
                this.factorMap[factor.id],
                "index_rule_id",
                this.orgIdToDefaultRuleMap[-4].id
            );
            Vue.set(
                this.factorMap[factor.id],
                "score_rule_id",
                this.orgIdToDefaultRuleMap[-3].id
            );
        }
    }

    private async deleteFactors(): Promise<void> {
        await Promise.all(
            this.selectedFactors.map(async (factor) => {
                this.deleteFactor(factor);
            })
        );
        this.deleteOpen = false;
    }

    private deleteFactor(factor: Factor): void {
        Vue.delete(this.factorMap, factor.id);
        if (factor.parent_id && this.factorTree[factor.parent_id]) {
            Vue.delete(
                this.factorTree[factor.parent_id],
                this.factorTree[factor.parent_id].findIndex(
                    (item) => item.id == factor.id
                )
            );
        }
    }

    private toggleOptions(val: boolean, index: number): void {
        if (val) {
            Vue.set(this.catFactors[index], "group", false);
            Vue.set(this.catFactors[index], "boolean", false);
        }
    }

    private toggleBoolean(val: boolean, index: number): void {
        if (val) {
            Vue.set(this.catFactors[index], "group", false);
            Vue.set(this.catFactors[index], "options", false);
        }
    }

    private toggleGroup(val: boolean, index: number): void {
        if (val) {
            Vue.set(this.catFactors[index], "options", false);
            Vue.set(this.catFactors[index], "boolean", false);
        }
    }

    private resetDecision(): void {
        this.creativity = 1;
        this.topicArea = "";
        this.persona = "";
        this.factorMap = {};
        this.factorTree = {};
        this.optionTree = {};
        this.catFactors = [];
        this.resetOpen = false;
        this.promtOpen = false;
        this.confirmOpen = false;
        this.selectedGroup = [];
    }

    private async addToDecision(): Promise<void> {
        if (
            this.rootId &&
            this.factorTree[this.rootFactorId] &&
            this.factorTree[this.rootFactorId].length
        ) {
            this.loading = true;
            await this.saveFactors(
                this.factorTree[this.rootFactorId],
                this.rootId,
                true
            );
            this.resetDecision();
            this.loading = false;
        } else {
            flashNotificationsModule.error({
                message: this.rootId
                    ? "No factors created"
                    : "Please select a group to add to",
                duration: 5000,
            });
        }
    }

    private newFactor(): void {
        this.editMode = false;
    }

    private async createNew(payload: {
        factor: Factor;
        options: string[] | null;
    }): Promise<void> {
        const ids = await this.getRandomIds(1, this.factorIds);
        if (ids.length) {
            const id = ids[0];
            const factor = {
                ...payload.factor,
                id: id,
                order_str: `${
                    this.factorTree[payload.factor.id]
                        ? this.factorTree[payload.factor.id].length
                        : 0
                }`,
            };
            Vue.set(this.factorMap, id, factor);
            if (this.factorTree[factor.parent_id ? factor.parent_id : -1]) {
                this.factorTree[factor.parent_id ? factor.parent_id : -1].push(
                    factor
                );
            } else {
                Vue.set(
                    this.factorTree,
                    factor.parent_id ? factor.parent_id : -1,
                    [factor]
                );
            }
            if (factor.value_type == "categorical" && payload.options) {
                Vue.set(this.optionTree, id, payload.options);
            }
        }
    }
}
