import {
    Comment,
    CommentCreateInput,
    CommentUpdateInput,
    Thread,
    ThreadCreateInput,
} from "@/graphql/API";
import {
    CreateComment,
    CreateThread,
    DeleteComment,
    DeleteThread,
    GetComments,
    GetOtherComments,
    GetThreads,
    UpdateComment,
} from "@/graphql/custom";
import { GRAPHQL_API } from "@/graphql/GraphqlAPI";
import {
    createComment,
    createThread,
    deleteComment,
    deleteThread,
    updateComment,
} from "@/graphql/mutations";
import { getComments, getOtherComments, getThreads } from "@/graphql/queries";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import Vue from "vue";
import {
    Action,
    config,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from "vuex-module-decorators";
import store from "..";
import Decisions from "./Decisions";

config.rawError = true;

const name = "comments";
if (module.hot) {
    if (store.hasModule(name)) {
        store.unregisterModule(name);
    }
}

@Module({ dynamic: true, store: store, name: name, namespaced: true })
export default class Comments extends VuexModule {
    keyedThreads: { [viewpoint_Choice_FactorId: string]: Thread[] } = {};
    threadDict: { [thread_id: string]: Thread } = {};
    keyedComments: { [thread_id: number]: Comment[] } = {};
    currentThreadId: number | null = null;
    otherComments: { [thread_id: number]: { [parentId: number]: Comment[] } } = {};

    get currCommentTree(): { [id: number]: Comment[] } | null {
        if (this.currentThreadId && this.keyedComments[this.currentThreadId]) {
            return this.keyedComments[this.currentThreadId].reduce(
                (acc, comment) => {
                    const key = comment.reply_to ? comment.reply_to : -1;
                    return {
                        ...acc,
                        [key]: acc.hasOwnProperty(key)
                            ? [...acc[key], comment]
                            : [comment],
                    };
                },
                {} as { [id: number]: Comment[] }
            );
        } else {
            return null;
        }
    }

    get currOtherComments(): { [parentId: number]: Comment[] } | null {
        if (this.currentThreadId && this.otherComments[this.currentThreadId]) {
            return this.otherComments[this.currentThreadId];
        } else {
            return null;
        }
    }

    @Mutation
    clear() {
        this.keyedComments = {};
        this.keyedThreads = {};
        this.threadDict = {};
    }

    @Mutation
    setCurrentThreadId(id: number | null): void {
        this.currentThreadId = id;
    }

    @Mutation
    setComments(payload: { thread_id: number; comments: Comment[] }): void {
        const thread_id = payload.thread_id;
        const comments = payload.comments;

        if (thread_id) {
            Vue.set(this.keyedComments, thread_id, comments);
        }
    }

    @Mutation
    setThreads(threads: Thread[]): void {
        for (const thread of threads) {
            const key = `${thread.viewpoint_id ?? "-1"}-${
                thread.choice_id ?? "-1"
            }-${thread.factor_id + (thread.row_id ? `-${thread.row_id}` : "")}`;

            if (this.keyedThreads[key] != null) {
                this.keyedThreads[key].push(thread);
            } else {
                Vue.set(this.keyedThreads, key, [thread]);
            }
            Vue.set(this.threadDict, thread.id, thread);
        }
    }

    @Mutation
    setThread(thread: Thread): void {
        const key = `${thread.viewpoint_id ?? "-1"}-${thread.choice_id}-${
            thread.factor_id + (thread.row_id ? `-${thread.row_id}` : "")
        }`;

        if (this.keyedThreads[key] != null) {
            this.keyedThreads[key].push(thread);
        } else {
            Vue.set(this.keyedThreads, key, [thread]);
        }
        Vue.set(this.threadDict, thread.id, thread);
    }

    @Mutation
    removeThread(thread: Thread): void {
        const key = `${thread.viewpoint_id ?? "-1"}-${thread.choice_id}-${
            thread.factor_id + (thread.row_id ? `-${thread.row_id}` : "")
        }`;

        if (this.keyedThreads[key] != null) {
            const findIndex = this.keyedThreads[key].findIndex(
                (searchThread) => searchThread.id === thread.id
            );

            if (findIndex >= 0) {
                this.keyedThreads[key].splice(findIndex, 1);
            }
        }
        Vue.delete(this.threadDict, thread.id);
    }

    @Mutation
    setComment(comment: Comment): void {
        if (comment.thread_id) {
            if (!this.keyedComments.hasOwnProperty(comment.thread_id)) {
                Vue.set(this.keyedComments, comment.thread_id, []);
            }
            const findIndex = this.keyedComments[comment.thread_id].findIndex(
                (searchComment) => searchComment.id === comment.id
            );
            if (findIndex >= 0) {
                this.keyedComments[comment.thread_id].splice(
                    findIndex,
                    1,
                    comment
                );
            } else this.keyedComments[comment.thread_id].push(comment);
        }
    }

    @Mutation
    removeComment(comment: Comment): void {
        if (comment.thread_id) {
            const commentIndex = this.keyedComments[
                comment.thread_id
            ].findIndex((searchComment) => searchComment.id == comment.id);

            if (commentIndex >= 0) {
                this.keyedComments[comment.thread_id].splice(commentIndex, 1);
            }
        }
    }

    @Mutation
    setOtherComments(payload: {
        thread_id: number;
        comments: Comment[];
    }): void {
        const threadId = payload.thread_id;
        Vue.set(this.otherComments, payload.thread_id, {});
        for (const comment of payload.comments) {
            const parentId = comment.reply_to != null ? comment.reply_to : -1;
            if (this.otherComments[threadId].hasOwnProperty(parentId)) {
                this.otherComments[threadId][parentId].push(comment);
            } else {
                Vue.set(this.otherComments[threadId], parentId, [comment]);
            }
        }
    }

    @Action
    async fetchThreads(payload: {
        decision_id: number;
        include_resolved: boolean;
    }): Promise<Thread[]> {
        if (payload.decision_id != null) {
            const threadRes = (await GRAPHQL_API.graphqlQueryRequest({
                query: getThreads,
                variables: {
                    ...payload,
                },
            })) as GraphQLResult<GetThreads>;
            const threads = threadRes.data?.getThreads;

            if (threads) {
                this.setThreads(threads);
                return threads;
            }
        }

        return [];
    }

    @Action
    async fetchComments(thread_id: number): Promise<Comment[]> {
        if (thread_id != null) {
            const commentRes = (await GRAPHQL_API.graphqlQueryRequest({
                query: getComments,
                variables: {
                    thread_id,
                },
            })) as GraphQLResult<GetComments>;
            const comments = commentRes.data?.getComments;

            if (comments) {
                this.setComments({ thread_id: thread_id, comments: comments });
                return comments;
            }
        }

        return [];
    }

    @Action
    async fetchOtherComments(payload: {
        thread_id: number;
        viewpoint_id?: number;
        choice_id: number;
        factor_id: number;
    }): Promise<Comment[]> {
        const commentRes = (await GRAPHQL_API.graphqlQueryRequest({
            query: getOtherComments,
            variables: {
                ...payload,
            },
        })) as GraphQLResult<GetOtherComments>;
        const comments = commentRes.data?.getOtherComments;

        if (comments) {
            this.setOtherComments({
                thread_id: payload.thread_id,
                comments: comments,
            });
            // this.setComments({ thread_id: thread_id, comments: comments });
            return comments;
        }

        return [];
    }

    @Action
    async createThread(input: ThreadCreateInput): Promise<Thread | null> {
        const threadRes = (await GRAPHQL_API.graphqlMutationRequest({
            query: createThread,
            variables: {
                input,
            },
        })) as GraphQLResult<CreateThread>;
        const thread = threadRes.data?.createThread;

        if (thread) {
            this.setThread(thread);
            return thread;
        }

        return null;
    }

    @Action
    async deleteThread(thread_id: number): Promise<Thread | null> {
        const threadRes = (await GRAPHQL_API.graphqlMutationRequest({
            query: deleteThread,
            variables: {
                thread_id,
            },
        })) as GraphQLResult<DeleteThread>;
        const thread = threadRes.data?.deleteThread;

        if (thread) {
            this.removeThread(thread);
            return thread;
        }

        return null;
    }

    @Action
    async createComment(input: CommentCreateInput): Promise<Comment[] | null> {
        const commentRes = (await GRAPHQL_API.graphqlMutationRequest({
            query: createComment,
            variables: {
                input,
            },
        })) as GraphQLResult<CreateComment>;
        const comment = commentRes.data?.createComment;

        if (comment) {
            comment.forEach((com) => {
                this.setComment(com);
            });
            return comment;
        }

        return null;
    }

    @Action
    async updateComment(input: CommentUpdateInput): Promise<Comment | null> {
        const commentRes = (await GRAPHQL_API.graphqlMutationRequest({
            query: updateComment,
            variables: {
                input,
            },
        })) as GraphQLResult<UpdateComment>;
        const comment = commentRes.data?.updateComment;

        if (comment) {
            this.setComment(comment);
            return comment;
        }

        return null;
    }

    @Action
    async deleteComment(comment_id: number): Promise<Comment[] | null> {
        const commentRes = (await GRAPHQL_API.graphqlMutationRequest({
            query: deleteComment,
            variables: {
                comment_id,
            },
        })) as GraphQLResult<DeleteComment>;
        const comments = commentRes.data?.deleteComment;

        if (comments) {
            comments.forEach((comment) => {
                if (comment.id === comment_id) this.removeComment(comment);
                else this.setComment(comment);
            });

            return comments;
        }

        return null;
    }

    @Action
    async changeCurrThreadId(id: number | null): Promise<void> {
        this.setCurrentThreadId(id);
    }
}
