
import { Vue, Component, Prop } from "vue-property-decorator";
import Logo from "@/components/ui/Logo.vue";
import { Urls } from "@/Urls";
import FlashMessage from "@/components/ui/FlashMessage.vue";

@Component({
    components: {
        Logo,
        FlashMessage,
    },
})
export default class WelcomeView extends Vue {
    @Prop({ default: false, type: Boolean })
    loading!: boolean;

    @Prop({ default: false, type: Boolean })
    invitation!: boolean;

    @Prop()
    workspaceId!: number;

    @Prop()
    permissionCode!: string;

    @Prop({ default: false, type: Boolean })
    splitScreen!: boolean;

    @Prop({ default: false, type: Boolean })
    backBtn!: boolean;

    @Prop({ default: false, type: Boolean })
    hideHeader!: boolean;

    @Prop({ default: false, type: Boolean })
    centerContent!: boolean;

    @Prop({ default: false, type: Boolean })
    vertCenter!: boolean;

    @Prop({ default: true, type: Boolean })
    showSignIn!: boolean;

    @Prop({ default: true, type: Boolean })
    signInLink!: boolean;

    @Prop({ default: false, type: Boolean })
    error!: boolean;

    @Prop({ default: "", type: String })
    nextLink!: string;

    @Prop({ default: "Get started", type: String })
    btnText!: string;

    @Prop()
    customHeader!: string;

    @Prop()
    customSubText!: string;

    @Prop({ default: true, type: Boolean })
    showSubText!: boolean;

    @Prop({ default: false, type: Boolean })
    smallHeader!: boolean;

    private readonly URLS = Urls;

    get header(): string {
        if (this.error) {
            return "Something went wrong";
        } else if (this.customHeader) {
            return this.customHeader;
        } else {
            return "Welcome!";
        }
    }

    get subText(): string {
        if (this.error) {
            return "Please refresh and try again.";
        } else if (this.customSubText) {
            return this.customSubText;
        } else {
            return "Viewpoint AI helps you make smarter decisions";
        }
    }
}
