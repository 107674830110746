var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"vp-login",class:{ 'vp-login--split': _vm.splitScreen }},[(!_vm.hideHeader)?_c('div',{staticClass:"vp-login__header"},[(_vm.backBtn)?[_c('button',{on:{"click":function($event){return _vm.$emit('back-button')}}},[_c('v-icon',{attrs:{"color":"#1E1919"}},[_vm._v("mdi-arrow-left")]),_vm._v(" Back ")],1)]:[_c('button',{staticClass:"vp-login__logo",on:{"click":function($event){return _vm.$emit('logo-button')}}},[_c('Logo',{attrs:{"icon":"","color":_vm.splitScreen ? '#1E1919' : '#FFF'}})],1)]],2):_vm._e(),_c('div',{staticClass:"vp-login__column vp-login__column--welcome"},[_c('div',{staticClass:"vp-login__background"}),_c('div',{staticClass:"vp-login__welcome-wrapper"},[_c('div',{staticClass:"vp-login__welcome"},[(_vm.loading && !_vm.error)?_c('div',{staticClass:"vp-login__loading"},[_c('Logo',{attrs:{"icon":"","color":"#FFF","loading":""}})],1):_vm._e(),_c('h1',{class:{ 'vp-h1-sm': _vm.smallHeader }},[_vm._v(" "+_vm._s(_vm.header)+" ")]),(_vm.showSubText)?_c('p',[_vm._v(_vm._s(_vm.subText))]):_vm._e()]),_c('div',{staticClass:"vp-login__links"},[(!_vm.loading && !_vm.error)?[(_vm.invitation)?[_c('a',{staticClass:"vp-login__explore",on:{"click":function($event){return _vm.$emit('to-signup-form')}}},[_c('span',[_vm._v(_vm._s(_vm.btnText))])])]:[_c('router-link',{staticClass:"vp-login__explore",attrs:{"to":{
                                path: _vm.URLS.SIGNUP,
                                query: { next: _vm.nextLink },
                            }}},[_c('span',[_vm._v("Explore Viewpoint")])])],(_vm.showSignIn)?_c('p',[_vm._v(" Not your first time? "),(_vm.signInLink)?_c('router-link',{attrs:{"to":{
                                path: _vm.URLS.LOGIN,
                                query: { next: _vm.nextLink },
                            }}},[_vm._v(" Sign in Here ")]):_c('button',{staticClass:"vp-login-link",on:{"click":function($event){return _vm.$emit('to-login-form')}}},[_vm._v(" Sign in Here ")])],1):_vm._e()]:_vm._e()],2)]),_c('div',{staticClass:"vp-signin__logo"},[_c('Logo',{attrs:{"color":"#FFF"}})],1)]),_c('div',{staticClass:"vp-login__column vp-login__signin"},[_c('div',{staticClass:"vp-login__form-container"},[_c('div',{staticClass:"vp-login__form-wrapper",class:{ 'vp-login__form-wrapper--center': _vm.vertCenter }},[_c('div',{staticClass:"vp-signin__slot"},[_c('div',{class:{ 'vp-signin__max': _vm.centerContent }},[_vm._t("default")],2)])])])]),_c('FlashMessage')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }