
import { Vue, Component, Prop, Watch, PropSync } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Apps from "@/store/modules/Apps";
import Decisions from "@/store/modules/Decisions";
import { App, Tab } from "@/graphql/API";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import VpDialog from "@/components/ui/VpDialog.vue";
import RichTextEditor from "@/components/ui/RichTextEditor.vue";
import FlashNotifications from "@/store/modules/FlashNotifications";
import HTMLEditor from "@/components/ui/texteditor/HTMLEditor.vue";

const appsModule = getModule(Apps);
const decisionsModule = getModule(Decisions);
const flashModule = getModule(FlashNotifications);

@Component({
    components: {
        VpDialog,
        RichTextEditor,
        HTMLEditor,
    },
})
export default class AppEditor extends Vue {
    @Prop({ default: false, type: Boolean })
    isEdit!: boolean;

    @Prop()
    app!: App;

    @Prop()
    tab!: Tab;

    private blankTab = {
        name: "",
        type: "CompareView",
        description: "",
    };

    private loading = false;

    private editTab = { ...this.blankTab };

    get typeIndex(): {
        [id: string]: { name: string; value: string; table?: boolean };
    } {
        return appsModule.tabTypes;
    }

    get typeOptions(): { name: string; value: string; table?: boolean }[] {
        return Object.values(this.typeIndex);
    }

    get currentDecisionId(): number | null {
        return decisionsModule.selectedDecisionId;
    }

    get title(): string {
        if (this.isEdit && this.tab) {
            return "Edit Tab";
        } else {
            return "Create Tab";
        }
    }

    get saveActive(): boolean {
        return true;
    }

    get tableType(): boolean {
        if (
            this.editTab.type &&
            this.typeIndex[this.editTab.type] &&
            this.typeIndex[this.editTab.type].table
        ) {
            return true;
        } else {
            return false;
        }
    }

    private async saveTab(): Promise<void> {
        if (this.tab) {
            this.loading = true;
            try {
                await appsModule.updateTab({
                    newTab: {
                        ...this.tab,
                        title: this.editTab.name,
                        type: this.editTab.type,
                        description: this.editTab.description,
                    },
                    appId: this.app.id,
                });
                flashModule.success({
                    message: `${this.editTab.name} saved`,
                    duration: 3000,
                });
            } catch (e) {
                const err = e as GraphQLResult<any>;
                const message = err?.errors
                    ? err.errors[0].message
                    : "Something went wrong.";
                console.log("%cError:", "color: red; font-weight: bold;");
                console.log(e);
                flashModule.error({
                    message: message,
                    duration: 3000,
                });
            }
            this.loading = false;
        }
    }

    private async createTab(): Promise<void> {
        if (this.currentDecisionId && this.app) {
            this.loading = true;
            try {
                const newTab = await appsModule.createTab({
                    decision_id: this.currentDecisionId,
                    title: this.editTab.name,
                    json: "",
                    edit_flags: this.tableType
                        ? JSON.stringify({ showAll: true })
                        : "",
                    display_flags: "",
                    filter_type: "choice",
                    row_type: "factor",
                    column_type: "viewpoint",
                    type: this.editTab.type,
                    description: this.editTab.description,
                });

                if (newTab) {
                    await appsModule.createAppTabMapping({
                        app_id: this.app.id,
                        tab_id: newTab.id,
                    });
                    this.editTab = { ...this.blankTab };
                    this.$emit("new-tab", newTab);
                    flashModule.success({
                        message: `${newTab.title} created in ${this.app.title}`,
                        duration: 3000,
                    });
                } else {
                    flashModule.error({
                        message: "Something went wrong",
                        duration: 3000,
                    });
                }
            } catch (e) {
                const err = e as GraphQLResult<any>;
                const message = err?.errors
                    ? err.errors[0].message
                    : "Something went wrong.";
                console.log("%cError:", "color: red; font-weight: bold;");
                console.log(e);
                flashModule.error({
                    message: message,
                    duration: 3000,
                });
            }
            this.loading = false;
        }
    }

    private async updateTextDescription(
        descriptionValue: string
    ): Promise<void> {
        this.editTab.description = descriptionValue;
    }

    private launchEditor(): void {
        if (this.app && this.tab) {
            this.$emit("edit-tab", { app: this.app, tab: this.tab });
        }
    }

    private close(): void {
        this.editTab = { ...this.blankTab };
    }

    mounted(): void {
        this.onTabChange();
    }

    @Watch("isEdit")
    onIsEdit(): void {
        this.onTabChange();
    }

    @Watch("tab", { immediate: true, deep: true })
    onTabChange(): void {
        if (this.isEdit && this.tab) {
            this.editTab = {
                name: this.tab.title,
                type: this.tab.type,
                description: this.tab.description ? this.tab.description : "",
            };
        } else {
            this.editTab = { ...this.blankTab };
        }
    }
}
