
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import Comments from "@/store/modules/Comments";
import { Comment } from "@/graphql/API";
import CommentDisplay from "@/components/comments/CommentDisplay.vue";

const commentModule = getModule(Comments);

@Component({
    name: "CommentList",
    components: {
        CommentDisplay,
    },
})
export default class CommentList extends Vue {
    @Prop()
    threadId!: number;

    @Prop({ default: -1, type: Number })
    rootId!: number;

    @Prop({ default: false, type: Boolean })
    showReply!: boolean;

    @Prop({ default: false, type: Boolean })
    padding!: boolean;

    @Prop()
    replyId!: number;

    @Prop({ type: String, default: "desc" })
    order!: string;

    @Prop({ default: 0, type: Number })
    depth!: number;

    @Prop({ default: true, type: Boolean })
    mainComments!: boolean;

    get commentTree(): { [id: number]: Comment[] } | null {
        if (this.mainComments) {
            return commentModule.currCommentTree;
        } else {
            return commentModule.currOtherComments;
        }
    }

    get comments(): Comment[] {
        if (this.commentTree && this.commentTree[this.rootId]) {
            return this.commentTree[this.rootId];
        } else {
            return [];
        }
    }

    get commentsOrdered(): Comment[] {
        return this.sortComments(this.comments, this.order);
    }

    private sortComments(comments: Comment[], order: string): Comment[] {
        return comments.sort((a, b) => {
            if (order == "desc") {
                return a.created < b.created ? 1 : -1;
            } else {
                return a.created > b.created ? 1 : -1;
            }
        });
    }

    private toggleReply(id: number | null): void {
        this.$emit("toggle-reply", id);
    }
}
