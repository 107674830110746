
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
@Component({
    components: {},
})
export default class CommentOffset extends Vue {
    @Prop({ default: 0, type: Number })
    depth!: number;

    @Prop({ default: false, type: Boolean })
    last!: boolean;
}
