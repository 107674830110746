
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

@Component({
    components: {},
})
export default class ToolBar extends Vue {
    @Prop({ default: false, type: Boolean })
    undoActive!: boolean;

    @Prop({ default: false, type: Boolean })
    redoActive!: boolean;

    @Prop({ default: false, type: Boolean })
    boldActive!: boolean;

    @Prop({ default: false, type: Boolean })
    italicActive!: boolean;

    @Prop({ default: false, type: Boolean })
    underlineActive!: boolean;

    @Prop({ default: false, type: Boolean })
    strikeActive!: boolean;

    @Prop({ default: false, type: Boolean })
    linkActive!: boolean;

    @Prop({ default: false, type: Boolean })
    numberListActive!: boolean;

    @Prop({ default: false, type: Boolean })
    bulletActive!: boolean;

    @Prop({ default: false, type: Boolean })
    tableActive!: boolean;

    @Prop({ default: false, type: Boolean })
    headerOneActive!: boolean;

    @Prop({ default: false, type: Boolean })
    headerTwoActive!: boolean;

    @Prop({ default: false, type: Boolean })
    headerThreeActive!: boolean;

    private tile = true;
    private headerMenu = false;
    private expanded = false;

    get listActive(): boolean {
        return this.bulletActive || this.numberListActive;
    }

    mounted(): void {
        this.onListActiveChange(this.listActive);
    }

    @Watch("listActive")
    onListActiveChange(val: boolean): void {
        if (val && !this.expanded) {
            this.expanded = true;
        }
    }
}
