
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Choice, Factor, Thread, Viewpoint } from "@/graphql/API";
import Decisions from "@/store/modules/Decisions";
import Comments from "@/store/modules/Comments";
import CommentTree from "@/components/comments/CommentTree.vue";
import CommentEditor from "@/components/comments/CommentEditor.vue";

const commentModule = getModule(Comments);
const decisionModule = getModule(Decisions);

@Component({
    components: {
        CommentTree,
        CommentEditor,
    },
})
export default class CommentPanel extends Vue {
    @Prop()
    factor!: Factor;

    @Prop()
    choice!: Choice;

    @Prop()
    viewpoint!: Viewpoint;

    private loading = false;
    private refresh = "";
    private replyId: number | null = null;
    private showOrderMenu = false;
    private order = "desc";
    private orderItems = [
        {
            value: "desc",
            label: "Desc",
        },
        {
            value: "asc",
            label: "Asc",
        },
    ];

    get choiceLabelSingular(): string {
        return decisionModule.choiceLabelSingular;
    }

    get commentKey(): string | null {
        if (this.choice && this.factor) {
            return `${this.viewpoint.id ?? "-1"}-${this.choice.id}-${
                this.factor.id
            }`;
        } else {
            return null;
        }
    }

    get thread(): Thread | null {
        if (this.commentKey) {
            return commentModule.keyedThreads[this.commentKey] &&
                commentModule.keyedThreads[this.commentKey][0]
                ? commentModule.keyedThreads[this.commentKey][0]
                : null;
        } else {
            return null;
        }
    }

    private setLoading(val: boolean): void {
        this.loading = val;
    }

    private setReplyId(id: number | null): void {
        this.replyId = id;
    }

    private async saveComment(val: string): Promise<void> {
        this.loading = true;
        let currThread = this.thread;
        if (currThread == null) {
            if (this.viewpoint && this.choice && this.factor) {
                currThread = await commentModule.createThread({
                    viewpoint_id: this.viewpoint.id,
                    choice_id: this.choice.id,
                    factor_id: this.factor.id,
                    name: "Comment Thread",
                    row_id: "",
                });
            }
        }
        if (currThread) {
            await commentModule.createComment({
                thread_id: currThread.id,
                comment: val,
                reply_to: null,
            });
            const currentDate = new Date();
            this.refresh = currentDate.toString();
        }

        this.loading = false;
    }
}
