
import { Component, Prop, Vue, Watch, PropSync } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Task, Factor, DocumentTablesResult, TableTitle } from "@/graphql/API";
import Factors from "@/store/modules/Factors";
import FlashNotifications from "@/store/modules/FlashNotifications";
import FactorDocumentUploader from "@/components/ui/FactorDocumentUploader.vue";

const modelModule = getModule(Factors);
const flashNotificationsModule = getModule(FlashNotifications);

@Component({
    components: { FactorDocumentUploader },
})
export default class DocumentPrompt extends Vue {
    @PropSync("topic")
    syncedTopic!: string;

    @PropSync("persona")
    syncedPersona!: string;

    @PropSync("creativity")
    syncedCreativity!: number;

    @PropSync("aiLoading")
    loading!: boolean;

    @Prop({ default: false, type: Boolean })
    testing!: boolean;

    @Prop()
    parent!: Factor;

    private includeTables = true;
    private documentId = "";
    private fileExtention = "";
    private firstPage = 1;
    private lastPage = 1;
    private factorsCreated = false;
    private fileUploaded = false;

    private testFactors = [
        {
            factor_id: 0,
            task_id: "63ab74aa-37b8-448c-9296-788863485103",
            name: "Location",
        },
        {
            factor_id: 1,
            task_id: "63ab74aa-37b8-448c-9296-788863485103",
            name: "Size of the Property",
        },
        {
            factor_id: 2,
            task_id: "63ab74aa-37b8-448c-9296-788863485103",
            name: "School District",
        },
        {
            factor_id: 3,
            task_id: "63ab74aa-37b8-448c-9296-788863485103",
            name: "Home Security",
        },
        {
            factor_id: 4,
            task_id: "63ab74aa-37b8-448c-9296-788863485103",
            name: "Child-Friendly Amenities",
        },
        {
            factor_id: 5,
            task_id: "63ab74aa-37b8-448c-9296-788863485103",
            name: "Budget",
        },
        {
            factor_id: 6,
            task_id: "63ab74aa-37b8-448c-9296-788863485103",
            name: "Home Condition",
        },
        {
            factor_id: 7,
            task_id: "63ab74aa-37b8-448c-9296-788863485103",
            name: "Resale Value",
        },
        {
            factor_id: 8,
            task_id: "63ab74aa-37b8-448c-9296-788863485103",
            name: "Home Safety",
        },
    ];

    get generateBtnEnabled(): boolean {
        if (this.syncedTopic && this.syncedTopic.length > 0) {
            if (this.syncedPersona && this.syncedPersona.length > 0) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    get extractBtnEnabled(): boolean {
        return !this.fileUploaded || this.firstPage < 0 || this.lastPage < 0;
    }

    private decrement() {
        this.syncedCreativity = this.syncedCreativity - 0.01;
    }
    private increment() {
        this.syncedCreativity = this.syncedCreativity + 0.01;
    }

    private setDocumentId(id: string, extention: string) {
        this.fileUploaded = true;
        this.fileExtention = extention;
        this.documentId = id;
    }

    private setLastPage(last: number) {
        this.fileUploaded = false;
        this.lastPage = last;
    }

    private async extractFactors(): Promise<void> {
        // if (this.syncedTopic && this.syncedTopic.length > 0) {
        this.loading = true;
        let first_page =
            this.firstPage == 0
                ? this.firstPage
                : this.firstPage < 0
                ? 0
                : this.firstPage - 1;

        if (this.testing) {
            this.$emit("extracted", {
                factors: this.testFactors,
                parent: this.parent ? this.parent.id : -1,
            });
        } else if (this.documentId) {
            try {
                let processResult = await modelModule.processDocumentTables({
                    document_id: this.documentId,
                    file_extension: this.fileExtention,
                    first_page: first_page,
                    last_page: this.lastPage,
                });
                let results = await this.getDocumentTablesResult(
                    processResult,
                    0
                );
                if (results && results.length) {
                    // TODO function for old ds functions
                    // this.$emit("extracted", {
                    //     factors: results,
                    //     parent: this.parent ? this.parent.id : -1,
                    // });
                    this.$emit("extracted", {
                        factors: results[0],
                        parent: this.parent ? this.parent.id : -1,
                    });
                } else {
                    this.$emit("extracted", {
                        factors: [],
                        parent: this.parent ? this.parent.id : -1,
                    });
                }
            } catch (error) {
                flashNotificationsModule.error({
                    message: `Unexpected error, system cannot read this document`,
                    duration: 5000,
                });
                this.loading = false;
            }
        } else {
            this.loading = false;
        }
        /*
         */
        this.loading = false;
        // }
    }

    private async getDocumentTablesResult(
        task: Task | null,
        requests: number
    ): Promise<TableTitle[] | null | undefined> {
        if (task) {
            try {
                let results = await modelModule.getDocumentTablesResult({
                    task_id: task.id,
                });
                if (results) {
                    let taskStatus = results.task.status;
                    if (taskStatus == "SUCCESS") {
                        return results.result;
                    } else if (taskStatus == "PENDING" && requests <= 6) {
                        /*
                            If pending then returns a promise of a timeout that waits
                            and calls the function again with requests + 1

                            Promise required an undefined type
                        */
                        return await new Promise<
                            TableTitle[] | null | undefined
                        >((resolve) => {
                            setTimeout(() => {
                                this.getDocumentTablesResult(
                                    task,
                                    requests + 1
                                ).then(
                                    (
                                        values: TableTitle[] | null | undefined
                                    ) => {
                                        resolve(values ? values : null);
                                    }
                                );
                            }, 15000);
                        }).then((values: TableTitle[] | null | undefined) => {
                            return values;
                        });
                    } else if (taskStatus == "FAILURE") {
                        flashNotificationsModule.error({
                            message: `Unexpected error, task has status FAILURE`,
                            duration: 5000,
                        });
                        return null;
                    } else {
                        flashNotificationsModule.warning({
                            message: `It looks like the process is taking too long. Try one more time.`,
                            duration: 10000,
                        });
                        return null;
                    }
                }
            } catch (error) {
                flashNotificationsModule.error({
                    message: `Unexpected error: ${error}`,
                    duration: 10000,
                });
                return null;
            }
        }
    }
}
