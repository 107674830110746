var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"vp-compare-table__header"},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"vp-compare__column-header",class:`vp-padding-x-${_vm.paddingX} vp-padding-y-${_vm.paddingY} ${
            _vm.items.length == 1 && _vm.fullColumn
                ? 'vp-compare__column-header--full'
                : ''
        } ${
            _vm.highlightId && _vm.highlightId == item.id
                ? 'vp-compare__column-header--root'
                : ''
        }`,style:(_vm.items.length == 1 && _vm.fullColumn
                ? ''
                : `width: ${_vm.columnWidth}px;`)},[(!(_vm.highlightId && _vm.highlightId == item.id))?[(
                    _vm.factorColumns && item && item.is_table | item.is_group
                )?_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('change-root', item.id)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.Icons["group"]))])],1):_vm._e()]:_vm._e(),(item.description)?_c('span',{directives:[{name:"tippy",rawName:"v-tippy",value:({ placement: 'bottom' }),expression:"{ placement: 'bottom' }"}],staticClass:"vp-compare__column-header__title",attrs:{"content":item.description}},[_vm._v(" "+_vm._s(item.name)+" ")]):_c('span',{staticClass:"vp-compare__column-header__title"},[_vm._v(" "+_vm._s(item.name)+" ")]),(_vm.showViewpointCell)?_c('div',{staticClass:"vp-compare__column-header__vp"},[[(_vm.showWeights)?_c('WeightCounterMenu',{attrs:{"indexes":_vm.indexes,"row-item":item,"data-item":_vm.dataItem,"editable":_vm.weightsEditable,"main-column":""}}):_vm._e()],(_vm.scoreRule)?_c('v-btn',{staticClass:"vp-compare__score-btn",attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleScoreRule(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-map-marker-distance ")])],1):_vm._e()],2):_vm._e(),(
                _vm.showChoiceData &&
                _vm.dataItem &&
                !(_vm.dataItem.is_group || _vm.dataItem.is_table)
            )?_c('div',{class:`vp-padding-top-${_vm.paddingY}`},[_c('FactorValueDisplay',{key:`${item.id}-${_vm.dataItem.id}`,attrs:{"factor":_vm.dataItem,"show-name":false,"editable":_vm.choiceEditable,"choice-id":item.id,"padding-x":_vm.paddingX,"padding-y":_vm.paddingY,"isThumbnailDisplay":true,"autoSaveMedia":true,"hide-desc":true,"value":_vm.choiceValues[item.id][_vm.dataItem.id]
                        ? _vm.choiceValues[item.id][_vm.dataItem.id]
                        : {}}})],1):_vm._e()],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }