
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Comment, UserPermission, Thread } from "@/graphql/API";
import Workspaces from "@/store/modules/Workspaces";
import Comments from "@/store/modules/Comments";
import Viewpoints from "@/store/modules/Viewpoints";
import Users from "@/store/modules/Users";
import CommentList from "@/components/comments/CommentList.vue";
import CommentEditor from "@/components/comments/CommentEditor.vue";
import CommentOffset from "@/components/comments/CommentOffset.vue";

const workspaceModule = getModule(Workspaces);
const commentModule = getModule(Comments);
const userModule = getModule(Users);
const viewpointModule = getModule(Viewpoints);

@Component({
    name: "CommentDisplay",
    components: {
        CommentList,
        CommentEditor,
        CommentOffset,
    },
})
export default class CommentDisplay extends Vue {
    @Prop()
    item!: Comment;

    @Prop()
    threadId!: number;

    @Prop({ default: false, type: Boolean })
    showReply!: boolean;

    @Prop({ default: false, type: Boolean })
    replyActive!: boolean;

    @Prop({ default: 0, type: Number })
    depth!: number;

    @Prop({ default: false, type: Boolean })
    last!: boolean;

    @Prop({ default: true, type: Boolean })
    mainComments!: boolean;

    private replySubmitting = false;
    private showMenu = false;
    private editActive = false;
    private loading = false;

    get currentUserId(): string | null {
        return userModule.currentUserId;
    }

    get usersComment(): boolean {
        if (this.currentUserId && this.item) {
            return this.currentUserId == this.item.author_id;
        } else {
            return false;
        }
    }

    get date(): string {
        if (this.item && this.item.created) {
            const d = new Date(this.item.created * 1000);
            return d.toDateString();
        } else {
            return "";
        }
    }

    get workspaceUsers(): { [id: string]: UserPermission } {
        return workspaceModule.workspaceUsers;
    }

    get author(): UserPermission | null {
        if (
            this.item &&
            this.item.author_id &&
            this.workspaceUsers[this.item.author_id]
        ) {
            return this.workspaceUsers[this.item.author_id];
        } else {
            return null;
        }
    }

    get userName(): string | null {
        if (this.author && (this.author.user.first || this.author.user.last)) {
            if (this.author.user.first && this.author.user.last) {
                return this.author.user.first + " " + this.author.user.last;
            } else if (this.author.user.first) {
                return this.author.user.first;
            } else if (this.author.user.last) {
                return this.author.user.last;
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    get commentTree(): { [id: number]: Comment[] } | null {
        if (this.mainComments) {
            return commentModule.currCommentTree;
        } else {
            return commentModule.currOtherComments;
        }
    }

    get replyNumber(): number {
        if (this.item && this.commentTree && this.commentTree[this.item.id]) {
            return this.commentTree[this.item.id].length;
        } else {
            return 0;
        }
    }

    get thread(): Thread | null {
        if (
            !this.mainComments &&
            this.item &&
            commentModule.threadDict[this.item.thread_id]
        ) {
            return commentModule.threadDict[this.item.thread_id];
        } else {
            return null;
        }
    }

    get threadViewpointId(): number | null {
        if (this.thread) {
            return this.thread.viewpoint_id;
        } else {
            return null;
        }
    }

    get threadViewpointName(): string | null {
        if (
            this.threadViewpointId &&
            viewpointModule.viewpointList[this.threadViewpointId]
        ) {
            return viewpointModule.viewpointList[this.threadViewpointId].name;
        } else {
            return null;
        }
    }

    private async saveReply(val: string): Promise<void> {
        this.replySubmitting = true;
        if (this.threadId && this.item) {
            await commentModule.createComment({
                thread_id: this.threadId,
                comment: val,
                reply_to: this.item.id,
            });
            this.$emit("toggle-reply", null);
        }
        this.replySubmitting = false;
    }

    private async updateComment(val: string): Promise<void> {
        if (this.item) {
            this.loading = true;
            await commentModule.updateComment({
                comment: val,
                id: this.item.id,
            });
            this.editActive = false;
            this.loading = false;
        }
    }

    private async deleteComment(): Promise<void> {
        if (this.item) {
            await commentModule.deleteComment(this.item.id);
        }
    }

    private editComment(): void {
        this.editActive = true;
    }

    private toggleReply(): void {
        if (this.item) {
            this.$emit("toggle-reply", this.item.id);
        }
    }

    private cancelComment(): void {
        this.$emit("toggle-reply", null);
    }
}
