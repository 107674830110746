
import { Component, Prop, Vue, Watch, PropSync } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Factor, Unit, Rule } from "@/graphql/API";
import Factors from "@/store/modules/Factors";
import Domains from "@/store/modules/Domains";
import Scoring from "@/store/modules/Scoring";
import RichTextEditor from "@/components/ui/RichTextEditor.vue";

const modelModule = getModule(Factors);
const domainsModule = getModule(Domains);
const scoringModule = getModule(Scoring);

@Component({
    components: {
        RichTextEditor,
    },
})
export default class DocumentFactorEditor extends Vue {
    @PropSync("factor")
    factorEdit!: Factor;

    @PropSync("options")
    optionsEdit!: string[];

    private newOptions = "";

    private editMode = true;

    get factorId(): number | null {
        return this.factorEdit ? this.factorEdit.id : null;
    }

    get valueTypes(): { text: string; id: string }[] {
        return modelModule.valueTypes.filter((item) => item.id !== "table");
    }

    get unitsList(): Unit[] {
        return domainsModule.unitsList;
    }

    get orgIdToDefaultRuleMap(): { [org_id: number]: Rule } {
        return scoringModule.defaultRules;
    }

    private updateTextDescription(descriptionValue: string) {
        this.factorEdit.description = descriptionValue;
    }

    private updateOption(val: string, index: number): void {
        Vue.set(this.optionsEdit, index, val);
    }

    private deleteOption(index: number): void {
        Vue.delete(this.optionsEdit, index);
    }

    private createOptions(): void {
        if (this.newOptions.length > 0) {
            const optionsList = this.newOptions.split(/\r?\n/);
            if (this.optionsEdit) {
                this.optionsEdit.push(...optionsList);
            } else {
                this.optionsEdit = [...optionsList];
            }
            this.newOptions = "";
        }
    }

    private convertToGroup(): void {
        this.$emit("convert-group", [this.factorEdit]);
    }

    private convertToMultipleChoice(): void {
        this.$emit("convert-to-multiplechoice", [this.factorEdit]);
    }

    private async changeType(val: string): Promise<void> {
        if (this.factorEdit) {
            Vue.set(this.factorEdit, "value_type", val);
            if (val != "group" && this.factorEdit.is_group) {
                Vue.set(this.factorEdit, "is_group", false);
            } else if (val != "table" && this.factorEdit.is_table) {
                Vue.set(this.factorEdit, "is_table", false);
            }
            switch (val) {
                case "number":
                    Vue.set(
                        this.factorEdit,
                        "index_rule_id",
                        this.orgIdToDefaultRuleMap[-6].id
                    );
                    Vue.set(
                        this.factorEdit,
                        "score_rule_id",
                        this.orgIdToDefaultRuleMap[-5].id
                    );
                    break;
                case "categorical":
                    Vue.set(
                        this.factorEdit,
                        "index_rule_id",
                        this.orgIdToDefaultRuleMap[-4].id
                    );
                    Vue.set(
                        this.factorEdit,
                        "score_rule_id",
                        this.orgIdToDefaultRuleMap[-3].id
                    );
                    this.convertToMultipleChoice();
                    break;
                case "date_time":
                    Vue.set(
                        this.factorEdit,
                        "index_rule_id",
                        this.orgIdToDefaultRuleMap[-10].id
                    );
                    Vue.set(
                        this.factorEdit,
                        "score_rule_id",
                        this.orgIdToDefaultRuleMap[-7].id
                    );
                    break;
                case "group":
                    Vue.set(this.factorEdit, "is_group", true);
                    Vue.set(
                        this.factorEdit,
                        "index_rule_id",
                        this.orgIdToDefaultRuleMap[-9].id
                    );
                    Vue.set(
                        this.factorEdit,
                        "score_rule_id",
                        this.orgIdToDefaultRuleMap[-8].id
                    );
                    this.convertToGroup();
                    break;
                case "table":
                    Vue.set(this.factorEdit, "is_table", true);
                    Vue.set(
                        this.factorEdit,
                        "index_rule_id",
                        this.orgIdToDefaultRuleMap[-9].id
                    );
                    Vue.set(
                        this.factorEdit,
                        "score_rule_id",
                        this.orgIdToDefaultRuleMap[-8].id
                    );
                    break;
                default:
                    Vue.set(
                        this.factorEdit,
                        "index_rule_id",
                        this.orgIdToDefaultRuleMap[-2].id
                    );
                    Vue.set(
                        this.factorEdit,
                        "score_rule_id",
                        this.orgIdToDefaultRuleMap[-1].id
                    );
            }
        }
    }

    @Watch("factorId")
    onFactorChange(): void {
        this.newOptions = "";
        this.editMode = true;
    }
}
