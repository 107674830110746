import { render, staticRenderFns } from "./CompareColumnHeaders.vue?vue&type=template&id=3f4aace6&scoped=true&"
import script from "./CompareColumnHeaders.vue?vue&type=script&lang=ts&"
export * from "./CompareColumnHeaders.vue?vue&type=script&lang=ts&"
import style0 from "./CompareColumnHeaders.vue?vue&type=style&index=0&id=3f4aace6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f4aace6",
  null
  
)

export default component.exports