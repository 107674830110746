
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DialogCardTitle from "@/components/ui/DialogCardTitle.vue";
import { getModule } from "vuex-module-decorators";
import { Auth, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import VpDialog from "@/components/ui/VpDialog.vue";

import FlashNotifications from "@/store/modules/FlashNotifications";

const flashNotificationsModule = getModule(FlashNotifications);
@Component({
    name: "FactorDocumentUploader",
    components: {
        DialogCardTitle,
        VpDialog,
    },
})
export default class FactorDocumentUploader extends Vue {
    @Prop({ default: false, type: Boolean })
    clearForm!: boolean;

    @Prop({ default: false, type: Boolean })
    includeTables!: boolean;

    private file: File | null = null;
    private userName = "";
    private fileUploaded = false;
    private isLoading = false;

    async mounted(): Promise<void> {
        let authUser = await Auth.currentAuthenticatedUser();
        this.userName = authUser.username;
    }

    get uploadedPdf(): File | string {
        return this.file ? URL.createObjectURL(this.file) : "";
    }

    get acceptableFileType(): string {
        // TODO return back accepted file formats after testing
        // return this.includeTables ? "application/pdf, .csv, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" : "application/pdf";
        return "application/pdf";
    }

    get placeholderMessage(): string {
        // TODO return back accepted file formats after testing
        // return this.includeTables ? "Input file (.pdf, .csv, .doc, .docx)" : "Input PDF file";
        return "Input PDF file";
    }

    private renderFile(e: any): void {
        this.fileUploaded = false;
        if (e && this.file) {
            console.log(this.file);
            const reader = new FileReader();
            let pageNumber = 1;
            reader.readAsBinaryString(this.file);
            reader.onload = (e: any) => {
                pageNumber = e.target.result.match(/\/Type[\s]*\/Page[^s]/g) ? e.target.result.match(/\/Type[\s]*\/Page[^s]/g).length : 1;
                this.$emit("loaded", pageNumber);
            };
        } else {
            this.$emit("loaded", 1);
        }
    }

    private async uploadMediaFile(): Promise<void> {
        this.isLoading = true;
        let file = this.file ? this.file : "";
        let fileType = this.file ? this.file.type : "";
        let fileExtension = this.file?.name.split('.').pop();
        const fileName = uuidv4();
        const key = `${this.userName}/${fileName}.${fileExtension}`;

        console.log(key);
        console.log(fileType);

        try {
            await Storage.put(key, file, {
                // level: "private",
                resumable: true,
                contentType: fileType,
                // customPrefix: {
                //     public:'private/'
                // }
                completeCallback: () => {
                    flashNotificationsModule.success({
                        message: `Successfully uploaded`,
                        duration: 5000,
                    });
                    this.fileUploaded = true;
                    this.isLoading = false;
                    this.$emit("uploaded", fileName, fileExtension);
                },
                errorCallback: (err) => {
                    this.fileUploaded = false;
                    this.isLoading = false;
                    flashNotificationsModule.error({
                        message: `Unexpected error while uploading ${err}`,
                        duration: 10000,
                    });
                },
            });
        } catch (error) {
            console.log(error);
            this.isLoading = false;
            flashNotificationsModule.error({
                message: `Unexpected error while uploading ${error}`,
                duration: 10000,
            });
        }
    }

    @Watch("clearForm")
    private onFactorsCreated() {
        this.file = null;
    }
}
