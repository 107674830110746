
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import {
    Factor,
    Choice,
    Viewpoint,
    CompareValues,
    Value,
    ScoreOptions,
    ScoreClassSettings,
    FactorOptions,
    ChartOptions,
    FactorSelection,
} from "@/graphql/API";
import Factors from "@/store/modules/Factors";
import Choices from "@/store/modules/Choices";
import FlashNotifications from "@/store/modules/FlashNotifications";
import CompareCell from "@/components/compare/CompareCell.vue";
import CompareTable from "@/components/compare/CompareTable.vue";
import FactorValueDisplay from "@/components/choices/FactorValueDisplay.vue";
import WeightCounterMenu from "@/components/weights/WeightCounterMenu.vue";
import RichTextEditor from "@/components/ui/RichTextEditor.vue";
import { updateFactorL } from "@/helpers/FactorHelper";
import CompareRow from "@/components/compare/ui/CompareRow.vue";
import CompareColumnMain from "@/components/compare/ui/CompareColumnMain.vue";
import CompareColumn from "@/components/compare/ui/CompareColumn.vue";
import Viewpoints from "@/store/modules/Viewpoints";
import { getScoresD } from "@/helpers/ScoreHelper";

const modelModule = getModule(Factors);
const choiceModule = getModule(Choices);
const viewpointModule = getModule(Viewpoints);
const flashModule = getModule(FlashNotifications);

@Component({
    name: "CompareRecursive",
    components: {
        CompareCell,
        CompareTable,
        FactorValueDisplay,
        WeightCounterMenu,
        RichTextEditor,
        CompareRow,
        CompareColumnMain,
        CompareColumn,
    },
})
export default class CompareRecursive extends Vue {
    @Prop({ type: Number, default: -1 })
    factorRoot!: number;

    @Prop({ type: Number, default: 0 })
    depth!: number;

    @Prop({
        default: () => ({
            rows: "factors",
            columns: "viewpoints",
            data: "choices",
        }),
        type: Object,
    })
    indexes!: {
        rows: "viewpoints" | "factors" | "choices";
        columns: "viewpoints" | "factors" | "choices";
        data: "viewpoints" | "factors" | "choices";
    };

    @Prop()
    dataItem!: Factor | Choice | Viewpoint | null;

    @Prop()
    values!: CompareValues;

    @Prop({ default: 200, type: Number })
    mainWidth!: number;

    @Prop({ default: 200, type: Number })
    columnWidth!: number;

    @Prop()
    factorRow!: Factor[];

    @Prop({ default: true, type: Boolean })
    choiceEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    scoresEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    customScore!: boolean;

    @Prop({ default: true, type: Boolean })
    weightsEditable!: boolean;

    @Prop({ default: true, type: Boolean })
    classRating!: boolean;

    @Prop({ default: true, type: Boolean })
    scoreRule!: boolean;

    @Prop()
    scoreDisplay!: ScoreOptions;

    @Prop()
    selectorSettings!: ScoreClassSettings;

    @Prop({ default: 2, type: Number })
    paddingX!: number;

    @Prop({ default: 2, type: Number })
    paddingY!: number;

    @Prop({ default: true, type: Boolean })
    borderX!: boolean;

    @Prop({ default: true, type: Boolean })
    borderY!: boolean;

    @Prop({ default: false, type: Boolean })
    fullColumn!: boolean;

    @Prop()
    hiddenFactors!: number[];

    @Prop()
    factorSelection!: FactorSelection;

    @Prop({ default: false, type: Boolean })
    groupOpen!: boolean;

    @Prop({ default: false, type: Boolean })
    parentOpen!: boolean;

    @Prop({ default: () => [], type: Array })
    groupIds!: number[];

    @Prop()
    decisionId!: number;

    @Prop({ default: () => [], type: Array })
    lines!: number[];

    @Prop({
        default: () => {
            return { factor_description: true };
        },
        type: Object,
    })
    factorDisplay!: FactorOptions;

    @Prop({ default: false, type: Boolean })
    highlight!: boolean;

    @Prop()
    highlightId!: number;

    private open = {};

    get groupActive(): boolean {
        return this.parentOpen && this.groupOpen;
    }

    get displayValues(): CompareValues {
        return {
            factors: this.factorRow ? this.factorRow : this.currentFactorTree,
            choices: this.values["choices"],
            viewpoints: this.values["viewpoints"],
        };
    }

    get rowItems(): Factor[] | Choice[] | Viewpoint[] {
        return this.displayValues[this.indexes["rows"]];
    }

    get activeChoices(): Choice[] {
        if (this.displayValues && this.displayValues.choices) {
            return this.displayValues.choices;
        } else {
            return [];
        }
    }

    get activeViewpoints(): Viewpoint[] {
        if (this.displayValues && this.displayValues.viewpoints) {
            return this.displayValues.viewpoints;
        } else {
            return [];
        }
    }

    get activeFactor(): Factor | null {
        if (
            this.indexes["data"] == "factors" &&
            this.dataItem &&
            modelModule.factorMap[this.dataItem.id]
        ) {
            return modelModule.factorMap[this.dataItem.id];
        } else {
            return null;
        }
    }

    get showDescription(): boolean {
        if (this.indexes) {
            return (
                this.indexes["rows"] == "factors" &&
                this.factorDisplay.factor_description
            );
        } else {
            return false;
        }
    }

    get currentFactorTree(): Factor[] {
        if (
            this.depth == 0 &&
            this.factorRoot != -1 &&
            this.indexes["columns"] != "factors"
        ) {
            /* If a different factor root is set then the group is 
                just the factor with that root ID */
            return modelModule.factorMap[this.factorRoot]
                ? [modelModule.factorMap[this.factorRoot]]
                : [];
        } else {
            if (modelModule.factorTree[this.factorRoot]) {
                return modelModule.factorTree[this.factorRoot].filter(
                    (factor) => {
                        return !this.hiddenFactors.includes(factor.id);
                    }
                );
            } else {
                return [];
            }
        }
    }

    get allChoiceValues(): { [choice_id: number]: { [id: string]: Value } } {
        return choiceModule.choiceValues;
    }

    get choiceValues(): { [id: number]: Value } {
        if (this.dataItem) {
            if (choiceModule.choiceValues[this.dataItem.id]) {
                return choiceModule.choiceValues[this.dataItem.id];
            }
        }
        return {};
    }

    get factorsChoice(): boolean {
        if (
            this.indexes["rows"] == "factors" &&
            this.indexes["data"] == "choices"
        ) {
            return true;
        } else {
            return false;
        }
    }

    get choicesFactor(): boolean {
        if (
            this.indexes["rows"] == "choices" &&
            this.indexes["data"] == "factors"
        ) {
            return true;
        } else {
            return false;
        }
    }

    get choiceDataMain(): boolean {
        return this.factorsChoice || this.choicesFactor;
    }

    /* Visibility Refactor */
    get showViewpointCell(): boolean {
        return this.indexes["columns"] == "choices" && this.dataItem != null;
    }
    get choicesFactors(): boolean {
        return this.indexes["rows"] == "factors";
    }

    get choicesViewpoints(): boolean {
        return this.indexes["rows"] == "viewpoints";
    }
    /* End of Visibility Refactor */

    private selectFactor(selectedFactor: Factor, selectedViewpoint: Viewpoint) {
        this.$emit("factor-select", {
            factor: selectedFactor,
            viewpoint: selectedViewpoint,
        });
    }

    private toggleChart(payload: ChartOptions) {
        this.$emit("toggle-charts", payload);
    }

    private updateTextDescription(descriptionValue: string, index: number) {
        let currentFactor = this.currentFactorTree[index];
        const defaultOptions = {
            style_class: "vp-body-1",
            categories: [],
        };

        if (currentFactor) {
            currentFactor.description = descriptionValue;

            updateFactorL({
                factor: currentFactor,
                parentId:
                    currentFactor.parent_id && currentFactor.parent_id != -1
                        ? currentFactor.parent_id
                        : -1,
                valueType: currentFactor.value_type,
                options: currentFactor.json ?? JSON.stringify(defaultOptions),
                format: currentFactor.format,
                m_score: currentFactor.m_score,
                m_index: currentFactor.m_index,
                weight: currentFactor.weight,
                score_rule_id: currentFactor.score_rule_id,
                index_rule_id: currentFactor.index_rule_id,
                set_id: currentFactor.set_id,
            });
        }
    }

    private async loadVpMappings(viewpoints: Viewpoint[]): Promise<void> {
        if (viewpoints.length) {
            await Promise.all(
                viewpoints.map(async (vp) => {
                    if (vp) {
                        await viewpointModule.fetchViewpointMappings({
                            viewpoint_id: vp.id,
                            root_only: this.factorRoot == -1 ? true : undefined,
                            parent_id:
                                this.factorRoot != -1
                                    ? this.factorRoot
                                    : undefined,
                        });
                    }
                })
            );
        }
    }

    private async loadChoiceValues(choices: Choice[]): Promise<void> {
        if (choices.length) {
            await Promise.all(
                choices.map(async (choice) => {
                    await choiceModule.getChoiceValues({
                        choice_id: choice.id,
                        root_only: this.factorRoot == -1 ? true : undefined,
                        parent_id:
                            this.factorRoot != -1 ? this.factorRoot : undefined,
                    });
                })
            );
        }
    }

    private openGroups(ids: number[]): void {
        ids.forEach((id) => {
            Vue.set(this.open, id, [0]);
        });
    }

    mounted(): void {
        this.onFactorRootChange();
        this.onGroupActiveChange(this.groupActive);
        this.onGroupIdsChange(this.groupIds, []);
    }

    @Watch("activeViewpoints", { immediate: true, deep: true })
    async onViewpointsChange(
        newVal: Viewpoint[],
        oldVal: Viewpoint[] = []
    ): Promise<void> {
        if (newVal && newVal.length) {
            this.loadVpMappings(newVal.filter((x) => !oldVal.includes(x)));
        }
    }

    @Watch("activeChoices", { immediate: true, deep: true })
    async onChoiceChange(
        newVal: Choice[],
        oldVal: Choice[] = []
    ): Promise<void> {
        if (newVal && newVal.length) {
            this.loadChoiceValues(newVal.filter((x) => !oldVal.includes(x)));
        }
    }

    @Watch("factorRoot")
    private async onFactorRootChange(): Promise<void> {
        if (this.factorRoot != -1 && !modelModule.factorTree[this.factorRoot]) {
            await modelModule.fetchChildFactors(this.factorRoot);
        }
        this.loadChoiceValues(this.activeChoices);

        this.loadVpMappings(this.activeViewpoints);
    }

    @Watch("groupIds")
    onGroupIdsChange(newVal: number[], oldVal: number[] = []): void {
        if (this.depth == 0) {
            this.openGroups(newVal.filter((x) => !oldVal.includes(x)));
        }
    }

    @Watch("groupActive")
    onGroupActiveChange(val: boolean): void {
        if (val && this.decisionId) {
            try {
                getScoresD({
                    d_id: this.decisionId,
                    fp_ids: [this.factorRoot],
                    c_ids:
                        this.activeChoices && this.activeChoices.length > 0
                            ? this.activeChoices.map((choice) => choice.id)
                            : [],
                    v_ids:
                        this.activeViewpoints &&
                        this.activeViewpoints.length > 0
                            ? this.activeViewpoints.map((val) => val.id)
                            : [],
                    root: false,
                });
            } catch (e) {
                flashModule.error({
                    message:
                        "Error fetching scores. Please refresh and try again.",
                    duration: 3000,
                });
            }
        }
    }
}
