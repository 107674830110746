import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: "#673ab7",
                hover: "#ece1fd",
                secondary: "#424242",
                backgroundMain: "#FFF",
                backgroundDark: "#f3f3f3",
                border: "#C4C4C4",
                textMain: "#252525",
                textLight: "#FFF",
                greyMain: "#C4C4C4",
                greyDark: "#939393",
                greyLight: "#F5F5F5",
                textContrast: "#FFF",
                viewpointColor: "#673ab7",
            },
            dark: {
                primary: "#673ab7",
                hover: "#daa1ff",
                secondary: "#424242",
                backgroundMain: "#1e1e1e",
                text: "#FFF",
                textLight: "#252525",
                greyMain: "#525252",
                greyDark: "#7C7C7C",
                greyLight: "#272727",
                textContrast: "#FFF",
                viewpointColor: "#673ab7",
            },
        },
    },
});

export default vuetify;
