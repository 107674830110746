
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Editor, EditorContent } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Heading from "@tiptap/extension-heading";
import Underline from "@tiptap/extension-underline";
import Strike from "@tiptap/extension-strike";
import Image from "@tiptap/extension-image";
import Table from "@tiptap/extension-table";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Link from "@tiptap/extension-link";
import Placeholder from "@tiptap/extension-placeholder";
import { v4 as uuidv4 } from "uuid";
import ToolBar from "@/components/ui/texteditor/ToolBar.vue";

@Component({
    components: {
        EditorContent,
        ToolBar,
    },
})
export default class HTMLEditor extends Vue {
    @Prop({ type: String, default: "" })
    value!: string;

    @Prop({ type: String, default: "/assets" })
    folderKey!: string;

    @Prop({ type: String, default: "" })
    placeHolder!: string;

    @Prop()
    minHeight!: string;

    @Prop({ type: String, default: "" })
    refreshKey!: string;

    @Prop({ default: false, type: Boolean })
    focusOnClick!: boolean;

    /* Save Options */
    @Prop({ default: false, type: Boolean })
    autoSave!: boolean;

    @Prop({ default: false, type: Boolean })
    showSave!: boolean;

    @Prop({ default: true, type: Boolean })
    saveActive!: boolean;

    @Prop({ type: String, default: "Save" })
    saveBtn!: string;

    @Prop({ type: String, default: "mdi-send" })
    saveIcon!: string;

    /* Values for Factor Input */
    @Prop({ default: null, type: Number })
    minLength!: number;

    @Prop({ default: null, type: Number })
    maxLength!: number;

    @Prop({ default: false, type: Boolean })
    loading!: boolean;

    private editor: Editor | null = null;
    private editValue: string | null = null;
    private focused = false;
    private editorActive = false;
    private wrapper: HTMLElement | null = null;
    private pasteTable = false;

    private rules = {
        isStringMinValid: (
            val: string,
            min: number | undefined
        ): string | boolean => {
            const re = /<\/*[a-z|:]+>/g;
            const taglessStr = val.replaceAll(re, "");
            if (min != undefined && taglessStr) {
                return taglessStr.length >= min
                    ? true
                    : `Length must be greater than ${min} characters.`;
            } else {
                return true;
            }
        },
        isStringMaxValid: (
            val: string,
            min: number | undefined,
            max: number | undefined
        ): string | boolean => {
            const re = /<\/*[a-z|:]+>/g;
            const taglessStr = val.replaceAll(re, "");
            if (max != undefined && taglessStr) {
                return taglessStr.length <= max
                    ? true
                    : `Length must be less than ${max} characters`;
            } else {
                return true;
            }
        },
    };

    get style(): string {
        return this.minHeight ? `min-height: ${this.minHeight}` : "";
    }

    get editorFocused(): boolean {
        return this.focused || this.editorActive;
    }

    get hasControl(): boolean {
        return this.$slots.hasOwnProperty("control");
    }

    get saveValid(): boolean {
        return this.saveActive;
    }

    get errMessages(): string {
        if (this.editor) {
            const minVal = this.rules.isStringMinValid(
                this.editor.getHTML(),
                this.minLength
            );
            const maxVal = this.rules.isStringMaxValid(
                this.editor.getHTML(),
                this.minLength,
                this.maxLength
            );
            if (minVal != true && minVal != false) {
                return minVal;
            }

            if (maxVal != true && maxVal != false) {
                return maxVal;
            }

            return "";
        } else {
            return "";
        }
    }

    mounted(): void {
        this.initEditor();
        this.initEditorEvent();
    }

    private async initEditor(): Promise<void> {
        this.editor = await this.createEditor();
        this.onValueChange(this.value);
        this.editor.on("update", () => {
            if (this.editor) {
                this.updateValue(this.editor);
            }
        });
    }

    private async createEditor(): Promise<Editor> {
        return new Editor({
            content: this.editValue,
            extensions: [
                StarterKit,
                Heading,
                Underline,
                Strike,
                Image.configure({ allowBase64: true }),
                Table.configure({ resizable: true }),
                TableRow,
                TableHeader,
                TableCell,
                Placeholder.configure({
                    placeholder: this.placeHolder,
                }),
                Link.configure({ autolink: true }),
            ],
        });
    }

    private focusEditor(): void {
        this.focused = true;
    }

    private blurEditor(): void {
        this.focused = false;
    }

    private updateValue(editor: Editor): void {
        this.editValue = editor.getHTML();
        /* If not autosave then live update to parent component */
        if (!this.autoSave) {
            this.updateParent();
        }
    }

    private setLink(): void {
        if (this.editor) {
            const previousUrl = this.editor.getAttributes("link").href;
            const url = window.prompt("URL", previousUrl);

            if (url) {
                if (url.length > 0) {
                    this.editor
                        .chain()
                        .focus()
                        .extendMarkRange("link")
                        .setLink({ href: url })
                        .run();
                } else {
                    this.editor
                        .chain()
                        .focus()
                        .extendMarkRange("link")
                        .unsetLink()
                        .run();
                }
            }
        }
    }

    /* Events for toggling editor */
    private initEditorEvent(): void {
        this.$el?.addEventListener("mousedown", this.handleEditorClick);
    }

    private initDocEvent(): void {
        document.addEventListener("mousedown", this.handleDocumentClick);
    }

    /* Function is called when user clicks inside Editor */
    private handleEditorClick(): void {
        this.editorActive = true;
        if (this.editor && this.focusOnClick) {
            this.editor.chain().focus();
        }
        this.initDocEvent();
        this.$el?.removeEventListener("mousedown", this.handleEditorClick);
    }

    /* Function is called when user clicks and editor is active */
    private handleDocumentClick(e: Event): void {
        if (this.editorActive) {
            if (!this.$el?.contains(e.target as Node)) {
                /* User has clicked outside of editor */
                this.editorActive = false;
                document.removeEventListener(
                    "mousedown",
                    this.handleDocumentClick
                );
                this.initEditorEvent();
                if (this.autoSave) {
                    this.updateParent();
                }
            }
        }
    }
    /* End of Events for toggling editor */

    private updateParent(): void {
        this.$emit("update-value", this.editValue);
    }

    private saveValue(): void {
        this.$emit("save-value", this.editValue);
    }

    /* Table Functions */
    private tableOption(val: string): void {
        if (this.editor) {
            switch (val) {
                case "add":
                    this.editor
                        .chain()
                        .focus()
                        .insertTable({
                            rows: 3,
                            cols: 3,
                            withHeaderRow: true,
                        })
                        .run()
                    break;
                case "add-column-before":
                    this.editor.chain().focus().addColumnBefore().run();
                    break;
                case "add-column-after":
                    this.editor.chain().focus().addColumnAfter().run();
                    break;
                case "delete-column":
                    this.editor.chain().focus().deleteColumn().run();
                    break;
                case "add-row-before":
                    this.editor.chain().focus().addRowBefore().run();
                    break;
                case "add-row-after":
                    this.editor.chain().focus().addRowAfter().run();
                    break;
                case "delete-row":
                    this.editor.chain().focus().deleteRow().run();
                    break;
                case "merge-cell":
                    this.editor.chain().focus().mergeCells().run();
                    break;
                case "split-cell":
                    this.editor.chain().focus().splitCell().run();
                    break;
                case "toggle-header-cell":
                    this.editor.chain().focus().toggleHeaderCell().run();
                    break;
                case "delete-table":
                    this.editor.chain().focus().deleteTable().run();
                    break;
            }
        }
    }

    @Watch("value")
    onValueChange(val: string): void {
        if (this.editor) {
            if (this.editor.getHTML() !== val) {
                this.editValue = val;
                this.editor.commands.setContent(val, false);
            }
        }
    }
}
