
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import Logo from "@/components/ui/Logo.vue";
import { Urls } from "@/Urls";
import { Auth } from "aws-amplify";
import PasswordInput from "@/components/ui/PasswordInput.vue";
import { Rules } from "@/helpers/FormRules";

@Component({
    components: { PasswordInput },
})
export default class SignupForm extends Vue {
    @Prop({ default: false, type: Boolean })
    enterEmail!: boolean;

    @Prop({ default: false, type: Boolean })
    active!: boolean;

    @Prop({ default: false, type: Boolean })
    invite!: boolean;

    private readonly URLS = Urls;
    private email = "";
    private password = "";
    private confirmPassword = "";
    private errorMessage = "";
    private isFormValid = false;
    private isVerifyFormValid = false;
    private first = "";
    private last = "";
    private redeemVerification = false;
    private user: any = null;
    private confirmationCode = "";
    private loading = false;
    private signupLoading = false;
    private rules = Rules;
    private showLoginBtn = false;
    private confirmLoading = false;
    private validated = false;

    get showVerification(): boolean {
        return this.redeemVerification || this.enterEmail;
    }

    private updatePassword(value: string): void {
        this.password = value;
    }

    private updateConfirmPassword(value: string): void {
        this.confirmPassword = value;
    }

    //Does signup call
    private async signup(): Promise<void> {
        this.signupLoading = true;
        try {
            this.user = await Auth.signUp({
                username: this.email,
                password: this.password,
                attributes: {
                    given_name: this.first, // optional
                    family_name: this.last, // optional - E.164 number convention
                    // other custom attributes
                },
                autoSignIn: {
                    // optional - enables auto sign in after user is confirmed
                    enabled: true,
                },
            });
            this.redeemVerification = true;
        } catch (error: any) {
            console.log("error signing up:", error);
            this.setErrorMsg(error.message);
        }
        this.signupLoading = false;
    }

    //Send confirmation code to complete signup
    private async confirmSignup(): Promise<void> {
        if (!this.invite) {
            this.$emit("loading-start");
            this.loading = true;
        }
        this.confirmLoading = true;
        try {
            await Auth.confirmSignUp(
                this.enterEmail ? this.email : this.user.user.username,
                this.confirmationCode
            );
            if (this.enterEmail) {
                this.showLoginBtn = true;
            }
            if (this.invite) {
                this.validated = true;
            }
        } catch (error: any) {
            this.setErrorMsg(error.message);
        }
        this.confirmLoading = false;
        if (!this.invite) {
            this.loading = false;
            this.$emit("loading-end");
        }
    }

    private setErrorMsg(error: any): void {
        this.errorMessage = error;
        setTimeout(() => {
            this.errorMessage = "";
        }, 5000);
    }

    private backToLogin(): void {
        this.showLoginBtn = false;
        this.$emit("to-login-form");
    }

    @Watch("active")
    onActiveChange(val: boolean): void {
        if (!val) {
            this.validated = false;
        }
    }
}
